<template>
<v-dialog v-model="dialogAS" persistent
                max-width="800">
        <v-stepper v-model="as">
            <v-stepper-header>
                <v-stepper-step :complete="as > 1"
                                step="1">
                    Tipologia Addebito
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="as > 2"
                                step="2">
                    Dettagli Addebito
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="as > 3"
                                step="3">
                    Riepilogo
                </v-stepper-step>


                <v-divider></v-divider>

                <v-stepper-step step="4">
                    Esito
                </v-stepper-step>
            </v-stepper-header>
            
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-container class="mb-12">
                        <v-form ref="formAS1"
                                v-model="validAS1">
                            <v-col cols="4" offset="4">
                                <v-radio-group :rules="[v=> !!v || 'indicare il tipo di consulenza']" v-model="idTipoConsulenza"
                                               column>
                                    <v-radio v-if="isContabilita"
                                             :value="tipiConsulenza[0].id"
                                             color="info"
                                             :label="tipiConsulenza[0].descrizione">
                                    </v-radio>
                                    <v-radio v-if="isLavoro"
                                             :value="tipiConsulenza[1].id"
                                             color="info"
                                             :label="tipiConsulenza[1].descrizione">
                                    </v-radio>
                                    <v-radio v-if="isPulizia"
                                             :value="tipiConsulenza[2].id"
                                             color="info"
                                             :label="tipiConsulenza[2].descrizione">
                                    </v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-form>
                    </v-container>
                    <v-row>
                        <v-col cols="2">
                            <v-btn :disabled="!validAS1" color="primary" depressed
                                    @click="stepAS1" :loading="loadingAS">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-container class="mb-12">
                        <v-form ref="formAS2"
                                v-model="validAS2">
                            <v-row>
                                <v-col cols="10" offset="1">
                                    <v-radio-group v-model="idTipoServizio"
                                                   :rules="[v=> !!v || 'indicare il tipo di servizio']"
                                                   column>

                                        <v-radio v-for="ts in tipiServizioEstesi" :key="ts.tipoServizio.id"
                                                 color="info"
                                                 :value="ts.tipoServizio.id"
                                                 @change="setImporto(ts.tariffa)"
                                                 :disabled="ts.tipoServizio.tipoPeriodicita && !ts.tariffa"
                                                 :label="ts.tipoServizio.descrizione+ (ts.tipoServizio.tipoPeriodicita && !ts.tariffa ? (selezione.length>1 ? ' (selezionare un solo cliente)':' (tariffa non impostata)'): (ts.tariffa ? ' ('+importoEuro(ts.tariffa.importo)+')' : '') )">
                                        </v-radio>
                                    </v-radio-group>
                                    <v-row dense>
                                        <v-col>
                                            <v-text-field v-model="descrizioneAddebito" class="mn-4"
                                                          label="Descrizione addebito"
                                                          outlined
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col v-if="isStudioPro">
                                            <v-select v-model="idProfessionistaAddebito"
                                                        :items="listaOperatoriProfessionisti"
                                                        item-value="professionista.id"
                                                        item-text="denominazioneNormalizzata"
                                                        label="Professionista di riferimento"
                                                        :rules="[v=> !!v || 'indicare il professionista']"
                                                        outlined
                                                        dense></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col>
                                            <v-text-field v-model="dataAddebito"
                                                          label="Data addebito"
                                                          outlined
                                                          :rules="[v=> (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa',v => isStudioProLight || checkDataOggiOFutura(v) || 'non è possibile usare una data nel passato']"
                                                          hint="gg/mm/aaaa"
                                                          persistent-hint
                                                          clearable
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <vuetify-money v-model="importoAddebito" class="ml-2"
                                                           label="Importo unitario"
                                                           outlined
                                                           :readonly="lockImporto"
                                                           :background-color="theme ? 'gray':'white'"
                                                           dense
                                                           :rules="[v=> !!v || 'inserire importo']"
                                                           :options="moneyOptions">
                                            </vuetify-money>
                                        </v-col>
                                        <v-col>
                                            <v-text-field v-model="numero"
                                                          label="Numero"
                                                          outlined
                                                          :rules="[v=> v>0 && /^[0-9]*$/.test(v) || 'valore numerico >0']"
                                                          persistent-hint
                                                          :hint="importoAddebitoEuro"
                                                          clearable
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row dense v-if="$store.state.parametri.isRateAddebito">
                                        <v-col>
                                            <v-checkbox v-model="rateizzazione"                                                    
                                                    :label="'Rateizzazione '+importoAddebitoEuro"
                                                    color="blue darken-3">
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-row dense v-if="rateizzazione">
                                        <v-col>
                                            <vuetify-money v-model="importoAcconto" 
                                                           label="Acconto Iniziale"
                                                           outlined
                                                           :readonly="lockImporto"
                                                           :background-color="theme ? 'gray':'white'"
                                                           dense
                                                           :rules="[v=> !!v || 'inserire importo']"
                                                           :options="moneyOptions">
                                            </vuetify-money>
                                        </v-col>                                       
                                        <v-col>
                                            <v-text-field v-model="numeroRate"
                                                          label="Numero Rate"
                                                          outlined
                                                          :rules="[v=> v>0 && /^[0-9]*$/.test(v) || 'valore numerico >0']"
                                                          persistent-hint                                                          
                                                          clearable
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <vuetify-money v-model="importoRataEuro" 
                                                           label="ImportoRata"
                                                           outlined
                                                           readonly
                                                           :background-color="theme ? 'gray':'white'"
                                                           dense
                                                           :options="moneyOptions">
                                            </vuetify-money>
                                        </v-col>
                                        <v-col>
                                            <v-text-field v-model="dataScadenzaPrimaRata"
                                                          label="Data scad. prima rata"
                                                          outlined
                                                          :rules="[v=> (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa',v => isStudioProLight ||checkDataOggiOFutura(v) || 'non è possibile usare una data nel passato']"
                                                          hint="gg/mm/aaaa"
                                                          persistent-hint
                                                          clearable
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row dense v-if="!progress">
                                        <v-checkbox class="ml-1" v-model="notifica"
                                                    label="notifica addebito in StudioPro App"
                                                    color="red darken-3">
                                        </v-checkbox>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <v-row dense>
                        <v-col cols="2">
                            <v-btn color="primary"
                                    @click="as = 1">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn :disabled="!validAS2" color="primary" depressed
                                    @click="as = 3">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-container class="mb-12">
                        <v-row>
                            <v-col cols="6">
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-format-list-bulleted-type</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{descrizioneTipoServizio}}</v-list-item-title>
                                        <v-list-item-subtitle>{{descrizioneTipoConsulenza}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{descrizioneAddebito}}</v-list-item-title>
                                        <v-list-item-subtitle>descrizione addebito</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-calendar</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{dataAddebito}}</v-list-item-title>
                                        <v-list-item-subtitle>data addebito</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line v-if="!progress">
                                    <v-list-item-icon>
                                        <v-icon style="color:red">mdi-alert-circle</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6" style="color:red">Nota importante</v-list-item-title>
                                        <v-list-item-subtitle>Saranno escluse automaticamente<br>le aziende <strong>cancellate</strong>, <strong>bloccate</strong> e<br>con gli <strong>addebiti sospesi</strong></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-cash</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{importoAddebitoEuro}}</v-list-item-title>
                                        <v-list-item-subtitle  v-if="rateizzazione">rateizzazione</v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="rateizzazione">{{importoAccontoEuro}} + {{numeroRate}}x{{importoRataEuro2}}</v-list-item-subtitle>                                        
                                        <v-list-item-subtitle  v-else>importo addebito</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-account-multiple</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{selezione.length}}</v-list-item-title>
                                        <v-list-item-subtitle>Clienti selezionati</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="isStudioPro" two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-tooltip-account</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{descrizioneProfessionista}}</v-list-item-title>
                                        <v-list-item-subtitle>Professionista di riferimento</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line v-if="!progress">
                                    <v-list-item-icon>
                                        <v-icon>mdi-bell-badge-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="notifica" class="text-h6">Notifica da inviare</v-list-item-title>
                                        <v-list-item-title v-else class="text-h6">Notifica da non inviare</v-list-item-title>
                                        <v-list-item-subtitle>StudioPro App</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-row>
                        <v-col cols="2">
                            <v-btn color="primary"
                                    @click="as = 2">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn color="success" depressed
                                    @click="sendAS">
                                Addebita spesa
                                <v-icon right>
                                    mdi-send
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="4">
                    <v-container class="mb-12" align="center">
                        <v-col cols="6" offset="3">
                            <v-card v-if="loadingAS" color="primary"
                                    dark>
                                <v-card-text>
                                    Esecuzione Operazione...
                                    <v-progress-linear indeterminate
                                                        color="white"
                                                        class="mb-0">
                                    </v-progress-linear>
                                </v-card-text>
                            </v-card>
                            <v-card v-if="!loadingAS && esitoASOK" color="success"
                                    dark>
                                <v-card-text>
                                    <v-icon dark>
                                        mdi-checkbox-marked-circle
                                    </v-icon>
                                    Operazione eseguita correttamente
                                </v-card-text>
                            </v-card>
                            <v-card v-if="!loadingAS && !esitoASOK" color="error"
                                    dark>
                                <v-card-text>
                                    <v-icon dark>
                                        mdi-alert-circle
                                    </v-icon>
                                    Errore durante l'esecuzione dell'operazione
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-container>

                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi"
                                    color="warning"
                                    :disabled="loadingAS">
                                Chiudi
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-dialog>
</template>


<script>

    import { callService, callPost, euro, gLocalData, gCheckData, gServerData } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
        props: ['type'],
        data: () => ({
            progress: false,
            theme:false,
            selezione: [],            
            as: 1,
            dialogAS: false,
            validAS1: false,
            validAS2: false,
            loadingAS: false,
            esitoASOK: false,
            idTipoConsulenza: '',
            tipiConsulenza: [],
            idTipoServizio: '',
            tipiServizioEstesi: [],
            idProfessionistaAddebito: '',
            descrizioneAddebito: '',
            dataAddebito: '',            
            importoAddebito: 0,
            importoAcconto: 0,
            importoRata: 0,
            dataScadenzaPrimaRata: '',
            rateizzazione: false,
            numero: 0,
            numeroRate: 0,
            periodi: [],
            listamodelli: [],
            listaoperatori: [],
            listascadenze: [],
            listaDoc: [],
            idModello: '',
            idScadenza: '',
            idOperatore: '',
            importoScadenza: 0,
            periodiConnessi: [],
            idPeriodo: '',
            numeroEdit: 0,
            lockImporto: false,
            notifica: true,
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },
            operatore: { id: '' },
            professionista: { id: '' },
            regimeIVA: { id: '' },
            regimeContabile: { id: '' },
            tipoCliente: { id: '' },
            fido: '',
            statoCliente: '1'
        }),
        methods: {
            localData(v) {
                return gLocalData(v);
            },
            importoEuro(v) {
                return euro(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            checkDataOggiOFutura(d) {                
                if(!d) return true;
                var tok = d.split("/");
                var dIso = new Date([tok[2], tok[1], tok[0]].join("-"));
                let oggi = new Date();
                oggi.setHours(0, 0, 0);
                return dIso.getTime()>=oggi.getTime();
            },
            serverData(v) {
                return gServerData(v);
            },
            async stepAS1() {
                this.loadingAS = true;
                let param;
                if (this.selezione.length > 1) {
                    param = [this.idTipoConsulenza];
                }
                else {
                    param = [this.idTipoConsulenza, this.selezione[0].cliente.id];
                }

                this.tipiServizioEstesi = await callService('api/tiposervizio/spot', param);
                this.numero = 1;
                this.as = 2;
                this.idProfessionistaAddebito = this.selezione[0].cliente.professionista.id;
                this.loadingAS = false;
            },
            async sendAS() {
                this.loadingAS = true;
                this.as = 4;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    data: this.serverData(this.dataAddebito),
                    idTipoServizio: this.idTipoServizio,
                    importo: this.importoAddebito,
                    numero: this.numero,
                    descrizione: this.descrizioneAddebito,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                    notifica: this.notifica,
                    idProfessionista: this.idProfessionistaAddebito,
                    progress: this.progress,
                    rateizzazione: this.rateizzazione ? true:false,
                    importoAcconto: this.importoAcconto,
                    numeroRate: this.numeroRate,
                    importoRata: this.importoRataEuro,
                    dataScadenzaPrimaRata: this.dataScadenzaPrimaRata ? this.serverData(this.dataScadenzaPrimaRata) : null,
                };

                this.esitoASOK = await callPost('api/addebito/spesa', param);
                if (this.esitoASOK) {
                    bus.$emit('cliente-changed', { lista: param.listaClienti.map(c => c.id), sessionID: "-1" });
                }
                this.loadingAS = false;
            },
            viewAS(selezione, type, progress) {
                if(type!=this.type) return;
                this.progress = progress;
                this.selezione = selezione;
                this.dialogAS = true;
                this.as = 1;
                this.idTipoConsulenza = null;
                this.idTipoServizio = null;
                this.loadingAS = false;
                this.$nextTick(() => {
                    this.$refs.formAS1.resetValidation();
                    this.$refs.formAS1.reset();
                    this.$refs.formAS2.resetValidation();
                    this.$refs.formAS2.reset();
                    this.$nextTick(() => {
                        this.notifica = progress ? false : true;
                        this.dataAddebito = this.localData(new Date());
                    });
                });
            },
            chiudi() {
                this.dialogAS = false;
            },            
            setImporto(tariffa) {
                this.lockImporto = tariffa != null;

                if (tariffa == null) this.importoAddebito = null;
                else this.importoAddebito = tariffa.importo;
            },
        },
        computed: {
            isLavoro() {
                return this.$store.state.lplApp.isLavoro;
            },
            isContabilita() {
                return this.$store.state.lplApp.isContabilita;
            },
            isPulizia() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isPulizia;
            },
            isManutenzione() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isManutenzione;
            },
            isChiamata() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isChiamata;
            },
            isServicePro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isPulizia || this.$store.state.lplApp.isChiamata || this.$store.state.lplApp.isManutenzione);
            },
            isStudioPro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isLavoro || this.$store.state.lplApp.isContabilita);
            },
            isStudioProLight() {
                return this.$store.state.parametri.isStudioProLight;
            },
            labelAS() {
                return "Addebito Spesa (" + this.selezione.length + ")";
            },
            importoRataEuro() {
                if(this.numeroRate>0) {
                    return ((this.importoAddebito * this.numero)- this.importoAcconto)/this.numeroRate;
                }
                else return 0;
            },
            importoRataEuro2() {
                if(this.numeroRate>0) {
                    return euro(((this.importoAddebito * this.numero)- this.importoAcconto)/this.numeroRate);
                }
                else return 0;
            },
            importoAddebitoEuro() {
                return euro(this.importoAddebito  * this.numero);
            },
            importoAccontoEuro() {
                return euro(this.importoAcconto);
            },
            importoScadenzaEuro() {
                return euro(this.importoScadenza);
            },
            descrizioneTipoServizio() {
                let ts = this.tipiServizioEstesi.find(x => x.tipoServizio.id === this.idTipoServizio);
                if (ts) return ts.tipoServizio.descrizione;
                else return "";
            },
            descrizioneTipoConsulenza() {
                let tc = this.tipiConsulenza.find(x => x.id === this.idTipoConsulenza);
                if (tc) return tc.descrizione;
                else return "";
            },
            descrizionePeriodo() {
                let p = this.periodi.find(x => x.id === this.idPeriodo);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneModello() {
                let p = this.listamodelli.find(x => x.id === this.idModello);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneScadenza() {
                let p = this.listascadenze.find(x => x.id === this.idScadenza);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneOperatore() {
                let p = this.listaoperatori.find(x => x.id === this.idOperatore);
                if (p) return p.denominazioneNormalizzata;
                else return "";
            },
            descrizioneProfessionista() {
                let p = this.listaOperatoriProfessionisti.find(x => x.professionista.id === this.idProfessionistaAddebito);
                if (p) return p.denominazioneNormalizzata;
                else return "";
            },
            bpNuovi() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero > 0).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpLock() {
                let n = this.clientiTariffa.filter(c => c.lock).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpLiberi() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero == 0).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpAbilitaSave() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero > 0).length;
                return n > 0;
            }
        },        
        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.theme = theme;

            this.tipiConsulenza = this.$store.state.listaTipoConsulenza;
            this.clientiEstesi = this.$store.state.clientiEstesi;
            this.listaOperatore = this.$store.state.listaOperatore;
            this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;
            this.listaTipoRegimeIVA = this.$store.state.listaTipoRegimeIVA;
            this.listaTipoRegimeContabile = this.$store.state.listaTipoRegimeContabile;
            this.listaTipoCliente = this.$store.state.listaTipoCliente;

            bus.$on('dialog-show-as', this.viewAS);
            this.loading = false;
        },    
        beforeDestroy() {
            bus.$off('dialog-show-as', this.viewAS)
        }
    }</script>


    
