import Vue from 'vue';
import App from './App.vue';
import Router from './router';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import Store from './store/store'
import IdleVue from "idle-vue";
import "./plugins/vuetify-money.js";
import UpdaterHub from './plugins/updater-hub'
import SyncHub from './plugins/sync-hub'
import VueCookie from 'vue-cookie'
import VueMermaid from "vue-mermaid/src";
import "./assets/reset.css";

Vue.config.productionTip = false;

export const bus = new Vue();

Vue.use(UpdaterHub);
Vue.use(SyncHub);
Vue.use(VueCookie);
Vue.use(VueMermaid);
Vue.use(Vuetify);

const eventsHub = new Vue();
Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store: Store,
    idleTime: 1000 * 60 * 10, // 10 minuti 
    startAtIdle: false
});

new Vue({
    vuetify: new Vuetify(),
    router: Router,
    store: Store,
    render: h => h(App),
}).$mount('#app')