<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-card-title>
                                <v-row>
                                    <v-col cols="3">
                                        <v-text-field v-model="search"
                                                      append-icon="mdi-magnify"
                                                      label="Ricerca un utente"
                                                      single-line
                                                      :hint="hintSelezione"
                                                      persistent-hint
                                                      outlined
                                                      clearable
                                                      @keyup="searchChange"
                                                      @click:clear="searchClear"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon x-large
                                                       color="teal"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="nuovo">
                                                    <v-icon class="ma-0">mdi-account-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Nuovo Utente</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-data-table :headers="isServicePro ? headersService : headers"
                                          :items="utenti"
                                          ref="tabella"
                                          :loading="loading"
                                          :items-per-page="nPag"
                                          class="elevation-1"
                                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                                          items-per-page-all-text="ciao"
                                          @current-items="filterItems"
                                          @pagination="pagination"
                                          :search="search"
                                          loading-text="Caricamento dati..."
                                          no-data-text="Nessun utente presente in archivio"
                                          no-results-text="Nessun utente corrispodende ai criteri di ricerca">
                                <template v-slot:item.menu="{item}">
                                    <!--<v-menu offset-y max-width="200">
                                        <template v-slot:activator="{ on, attrs }">
                                            <td v-bind="attrs"
                                                v-on="on">
                                                <v-icon color="orange darken-2">
                                                    mdi-launch
                                                </v-icon>
                                            </td>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="anagrafica(item)">
                                                <v-list-item-title>
                                                    <v-icon color="deep-orange darken-4">
                                                        mdi-account
                                                    </v-icon>
                                                    Anagrafica
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>-->
                                    <v-row>
                                        <v-col class="mx-n4">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-3" icon @click="anagrafica(item)"
                                                           v-bind="attrs"
                                                           v-on="on">
                                                        <v-icon color="amber">
                                                            mdi-account-edit
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Anagrafica</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </template>

                                <template v-slot:item.operatore.isProfessionista="{ item }">
                                    <v-simple-checkbox v-model="item.operatore.isProfessionista"
                                                       disabled>
                                    </v-simple-checkbox>
                                </template>

                                <template v-slot:item.operatore.isAccessoLimitato="{ item }">
                                    <v-simple-checkbox v-model="item.operatore.isAccessoLimitato"
                                                       disabled>
                                    </v-simple-checkbox>
                                </template>

                                <template v-slot:item.isReception="{ item }">
                                    <v-simple-checkbox v-model="item.isReception"
                                                       disabled>
                                    </v-simple-checkbox>
                                </template>

                                <template v-slot:item.isGestoreStudio="{ item }">
                                    <v-simple-checkbox v-model="item.isGestoreStudio"
                                                       disabled>
                                    </v-simple-checkbox>
                                </template>

                                <template v-slot:item.operatore.mail="{ item }">
                                    <span v-text="item.operatore.mail" />
                                    <br />
                                    <span class="font-weight-light" v-text="item.operatore.telefono" />
                                </template>


                                <template v-slot:item.operatore.isCancellato="{ item }">
                                    <v-tooltip bottom v-if="item.operatore.isCancellato">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="blue"
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   @click="abilita(item,true)">
                                                <v-icon color="error darken-2">mdi-account-off</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Abilita utente</span>
                                    </v-tooltip>

                                    <v-tooltip bottom v-else-if="!item.operatore.isCancellato">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="blue"
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   @click="abilita(item,false)">
                                                <v-icon color="green darken-2">mdi-account</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Disabilita utente</span>
                                    </v-tooltip>
                                </template>

                            </v-data-table>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <utente-dialog />

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-container>   

</template>




<script>
    import { callService, callPost, euro, Snack } from '@/modules/utilities.js'
    import UtenteDialog from '@/views/components/UtenteDialog.vue'
    import { bus } from '@/main.js'

    export default {
                components: {
                    UtenteDialog
                },  
        data: () => ({
                    snackSuccess: false,
                    snackError: false,
                    snackCancel: false,
                    snackRemote: false,
                    dialogUtente: false,
                    selezione: [],
                    filtro: [],
                    statoAll: false,
                    loading: true,
                    showError: false,
                    viewMenuUtente: false,
                    x: 0,
                    y: 0,
                    search: '',
                    utente: {
                        id:''
                    },
                    utenti: [],
                    headers: [
                        { text: 'Azioni', value: 'menu', width: '5%' },
                        { text: 'Utente', value: 'username', width: '15%' },
                        { text: 'Nominativo', value: 'operatore.denominazioneNormalizzata', width: '15%' },
                        { text: 'Mail/Telefono', value: 'operatore.mail', width: '15%' },
                        { text: 'Professionista', value: 'operatore.isProfessionista', width: '5%' },
                        { text: 'Reception', value: 'isReception', width: '5%' },
                        { text: 'Gestore Studio', value: 'isGestoreStudio', width: '5%' },
                        { text: 'Accesso limitato', value: 'operatore.isAccessoLimitato', width: '5%' },
                        { text: 'Status', value: 'operatore.isCancellato', width: '5%' }
                    ],
                    headersService: [
                        { text: 'Azioni', value: 'menu' },
                        { text: 'Utente', value: 'username' },
                        { text: 'Nominativo', value: 'operatore.denominazioneNormalizzata' },
                        { text: 'Mail/Telefono', value: 'operatore.mail' },
                        //{ text: 'Professionista', value: 'operatore.isProfessionista' },
                        { text: 'Amministrazione', value: 'isReception' },
                        { text: 'Gestore Azienda', value: 'isGestoreStudio' },
                        { text: 'Accesso limitato', value: 'operatore.isAccessoLimitato' },
                        { text: 'Status', value: 'operatore.isCancellato' }
                    ],
                    nPag:8,
                    oggetto:'',
                    testo:'',
                    conteggioUtenti: [],
                    notifica: true,
                    moneyOptions: {
                        locale: "it-IT",
                        prefix: "€",
                        suffix: "",
                        length: 10,
                        precision: 2
                    }
                }),
                methods: {
                    searchChange(){
                        this.selezione=[];
                    },
                    searchClear(){
                        this.selezione=[];
                    },
                    filterItems(v){
                        this.$nextTick(() => {
                            this.filtro=this.$refs.tabella.$children[0].filteredItems;
                        });
                    },
                    anagrafica(utente) {
                        this.utente = JSON.parse(JSON.stringify(utente));
                        bus.$emit('dialog-show-utente', this.utente);
                        //this.$router.replace({ name: "utente", query: { id: utente.id } });
                    },                    
                    nuovo() {
                        this.utente.id = "0";
                        bus.$emit('dialog-show-utente', this.utente);
                        //this.$router.replace({ name: "cliente", query: { id: "0" } });
                    },
                    onSnackSuccess() {
                            this.snackSuccess = true;
                    },
                    async abilita(item, value) {                        
                        bus.$emit('msg-open', { message: value ? "Abilitare l'utente?" :"Disabilitare l'utente?", item });
                        bus.$on('msg-cancel', this.abilitaCancel);
                        if (value) bus.$on('msg-ok', this.abilitaOk);
                        else       bus.$on('msg-ok', this.disabilitaOk);
                    },
                    async abilitaCancel(item) {
                        bus.$off('msg-cancel', this.abilitaCancel);
                        bus.$off('msg-ok', this.abilitaOk);
                        bus.$off('msg-ok', this.disabilitaOk);
                    },
                    async abilitaOk(item) {
                        await this.setStatoUtente(item, true);
                    },
                    async disabilitaOk(item) {
                        await this.setStatoUtente(item, false);
                    },
                    async setStatoUtente(item,value) {
                        bus.$off('msg-cancel', this.abilitaCancel);
                        bus.$off('msg-ok', this.abilitaOk);

                        this.utente = JSON.parse(JSON.stringify(item));

                        let paramJson = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            utente: this.utente,
                            abilita: value
                        };


                        this.loading = true;

                        let result = await callPost('api/utente/abilita', paramJson);

                        if (result) {
                            bus.$emit('utente-changed-bus', { lista: [this.utente.id], sessionID: "-1" });
                            this.snackSuccess = true;
                        }
                        else {
                            this.snackError = true;
                        }
                        this.loading = false;
                    },
                    pagination(pag) {
                        let nPag = pag.itemsPerPage;
                        this.$cookie.set('utenti-pagination', nPag.toString(), { expires: '1Y' });
                    }

                },
                computed: {
                    hintSelezione() {
                        if(this.selezione.length==1) return "1 utente selezionato su "+this.filtro.length;
                        if(this.selezione.length>1) return this.selezione.length+" utenti selezionati su "+this.filtro.length;
                        return "";
                    },
                    isLavoro() {
                        return this.$store.state.lplApp && this.$store.state.lplApp.isLavoro;
                    },
                    isContabilita() {
                        return this.$store.state.lplApp && this.$store.state.lplApp.isContabilita;
                    },
                    isPulizia() {
                        return this.$store.state.lplApp && this.$store.state.lplApp.isPulizia;
                    },
                    isManutenzione() {
                        return this.$store.state.lplApp && this.$store.state.lplApp.isManutenzione;
                    },
                    isChiamata() {
                        return this.$store.state.lplApp && this.$store.state.lplApp.isChiamata;
                    },
                    isServicePro() {
                        return this.$store.state.lplApp && (this.$store.state.lplApp.isPulizia || this.$store.state.lplApp.isChiamata || this.$store.state.lplApp.isManutenzione);
                    },
                    isStudioPro() {
                        return this.$store.state.lplApp && (this.$store.state.lplApp.isLavoro || this.$store.state.lplApp.isContabilita);
                    },
                },
                async created() {
                    this.utenti = this.$store.state.utenti;

                    this.loading = false;                    
                    bus.$on('utente-snack-succes', this.onSnackSuccess);

                    this.nPag = Number(this.$cookie.get('utenti-pagination')); //leggo il coockie della paginazione
                },
                beforeDestroy() {                    
                    bus.$off('utente-snack-succes', this.onSnackSuccess);
                }
            }</script>
