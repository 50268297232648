import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login.vue"
import Chat from "../views/Chat.vue"
import Clienti from "../views/Clienti.vue"
import Dashboard from "../views/Dashboard.vue"
import Calendario from "../views/Calendario.vue"
import CalendarioService from "../views/CalendarioService.vue"
import Cassa from "../views/Cassa.vue"
import Configurazione from "../views/Configurazione.vue"
import Utenti from "../views/Utenti.vue"
import Agenti from "../views/Agenti.vue"
import LPLApp from "../views/LPLApp.vue"
import Scadenze from "../views/Scadenze.vue"
import Scadenziario from "../views/Scadenziario.vue"
import Pratiche from "../views/Pratiche.vue"
import Wizard from "../views/Wizard.vue"
import CopyPartition from "../views/CopyPartition.vue"
import Rubrica from "../views/Rubrica.vue"
import Antiriciclaggio from "../views/Antiriciclaggio.vue"
import Tariffe from "../views/Tariffe.vue"
import Report from "../views/Report.vue"
import ReportLight from "../views/ReportLight.vue"
import Statistiche from "../views/Statistiche.vue"
import ServCon from "../views/ServCon.vue"
import Anno from "../views/Anno.vue"


Vue.use(VueRouter)

export default new VueRouter({
    routes: [        
        {
            path: "/login",
            name: "login",
            component: Login
        },
        {
            path: "/clienti",
            name: "clienti",
            component: Clienti
        },
        {
            path: "/rubrica",
            name: "rubrica",
            component: Rubrica
        },
        {
            path: "/antiriciclaggio",
            name: "antiriciclaggio",
            component: Antiriciclaggio
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard
        },
        {
            path: "/chat",
            name: "chat",
            component: Chat
        },
        {
            path: "/calendario",
            name: "calendario",
            component: Calendario
        },
        {
            path: "/calendario_service",
            name: "calendario_service",
            component: CalendarioService
        },
        {
            path: "/configurazione",
            name: "configurazione",
            component: Configurazione
        },
        {
            path: "/scadenze",
            name: "scadenze",
            component: Scadenze
        },  
        {
            path: "/scadenziario",
            name: "scadenziario",
            component: Scadenziario
        },        
        {
            path: "/cassa",
            name: "cassa",
            component: Cassa
        },
        {
            path: "/pratiche",
            name: "pratiche",
            component: Pratiche
        },
        {
            path: "/utenti",
            name: "utenti",
            component: Utenti
        },
        {
            path: "/agenti",
            name: "agenti",
            component: Agenti
        },
        {
            path: "/lplapp",
            name: "lplapp",
            component: LPLApp
        },
        {
            path: "/report",
            name: "report",
            component: Report
        },
        {
            path: "/reportlight",
            name: "reportlight",
            component: ReportLight
        },
        {
            path: "/tariffe",
            name: "tariffe",
            component: Tariffe
        },        
        {
            path: "/statistiche",
            name: "statistiche",
            component: Statistiche
        },
        {
            path: "/servcon",
            name: "servcon",
            component: ServCon
        },
        {
            path: "/anno",
            name: "anno",
            component: Anno
        },
        {
            path: "/wizard",
            name: "wizard",
            component: Wizard
        },
        {
            path: "/copypartition",
            name: "copypartition",
            component: CopyPartition
        }
    ],
    mode: 'history'
})