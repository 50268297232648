import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        packageVersion: process.env.PACKAGE_VERSION || '0',

        conf: { isService:false }, //indica se servicePro o studioPro

        logged: false, //accesso effettuato

        partition: '', //partizione corrente

        utente: null, //utente connesso

        admin: false, //privilegi amministratore

        idAnnoContabile: null, //id anno contabile attivo

        anno: null, //anno contabile

        annoAperto: true,

        selezione: [], //selezione impostata nella tabella clienti

        searchState: ['','','','','','',''], //stato di ricerca usata nella tabella clienti

        statoAll: false, //stato del pulsante seleziona tutti in tabella clienti


        //tabelle di conoscenza fisse
        lplApp: null,
        parametri: null,
        listaTipoRecapito : [],
        listaTipoCliente: [],
        listaTipoIndirizzario: [],
        listaTipoAttivita: [],
        listaOperatore: [],
        listaOperatoriProfessionisti:[],
        listaPeriodicitaContabile: [],
        listaPeriodicitaLavoro: [],        
        listaTipoPagamento: [],
        listaTipoConsulenza: [],
        //listaComuni: [],

        //clienti
        clientiEstesi: [],
        //utenti
        utenti: [],
        utentiClienti: [],
        sessionID: null,
        chatOpen: false //indica se la chat è aperta, in questo caso si by-passano le notifiche
    },

    getters: {
        // Here we will create a getter
        appVersion: (state) => {
            return state.packageVersion
        }
    },

    mutations: {
        logout(state) {          
            state.logged = false;
        },
        login(state) {           
            state.logged = true;
        },
        setPartition(state, part) {
            state.partition = part;
        },
        setUtente(state, ut) {
            state.utente = ut;
        },
        setAdmin(state, ad) {
            state.admin = ad;
        },
        setSelezione(state, sel) {
            state.selezione = sel;
        },
        setSearch(state, s) {
            state.searchState = s;
        },
        setStatoAll(state, s) {
            state.statoAll = s;
        },
        setIDAnnoContabile(state, id) {
            state.idAnnoContabile = id;
        },
        setAnno(state, anno) {
            state.anno = anno;
        },
        setAnnoAperto(state, aperto) {
            state.annoAperto = aperto;
        }
    },

    actions: { 
        logout(context) {           
            context.commit('logout')           
        },
        login(context) {
            context.commit('login')
        },      
        setPartition(context, part) {
            context.commit('setPartition',part)
        },
        setAdmin(context, admin) {
            context.commit('setAdmin', admin)
        },
        setUtente(context, utente) {
            context.commit('setUtente', utente)
        },
        setSelezione(context, sel) {
            context.commit('setSelezione', sel)
        },
        setSearchState(context, s) {
            context.commit('setSearch', s)
        },
        setStatoAll(context, s) {
            context.commit('setStatoAll', s)
        },
        setIDAnnoContabile(context, id) {
            context.commit('setIDAnnoContabile', id)
        },
        setAnno(context, anno) {
            context.commit('setAnno', anno)
        },
        setAnnoAperto(context, aperto) {
            context.commit('setAnnoAperto', aperto)
        }
    }
});