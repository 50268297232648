<template>
    <v-card class="ma-2" :loading="loading" color="teal darken-2" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-calendar
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Appuntamenti Oggi: {{listaAppuntamenti.length}}
            </span>
            <v-spacer></v-spacer>
            <v-btn icon @click="apriCalendario" class="ml-2">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-data-table :headers="headers"
                          :items="listaAppuntamenti"
                          :items-per-page="5"
                          class="elevation-0 transparent"
                          disable-items-per-page
                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[5]
                                          }"
                          no-data-text="Nessun appuntamento in calendario">

                <template v-slot:item.ore="{ item }">
                    <span v-text="item.oraInizio" />
                    <br />
                    <span v-text="item.oraFine" />
                </template>
                <template v-slot:item.descrizione="{ item }">
                    <span v-text="item.testoAppuntamento" />
                    <br />
                    <span v-text="item.partecipanti" />
                </template>               
            </v-data-table>
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>

    </v-card>
</template>



<script>
    import { callService, gLocalDateTime } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
        data: () => ({
            loading: false,
            hCard: 450,
            listaAppuntamenti: [],
            appuntamento:{},
            headers: [
                { text: 'Ore', value: 'ore' },
                { text: 'Descrizione', value: 'descrizione' },
            ],
        }),
        methods: {            
            localDateTime(v) {
                return gLocalDateTime(v);
            },
            apriCalendario() {
                this.$router.replace({ name: "calendario" }).catch(() => { });
            },
            async onPraticaChanged(){
                await this.internalCreated();
            },
            async internalCreated() {
                this.loading = true;
                let param = [this.$store.state.utente.operatore.id];
                this.listaAppuntamenti = await callService("api/dashboard/appuntamentiOggi", param);
                this.loading = false;
            }
        },
        computed: {
            
        },
        async created() {
            await this.internalCreated();
        },        
        beforeDestroy() {
        }
    }</script>

