<template>
    <v-card class="ma-2" :loading="loading" color="orange darken-2" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-calendar
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Appuntamenti da Confermare: {{listaAppuntamenti.length}}
            </span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
            <v-data-table :headers="headers"
                          :items="listaAppuntamenti"
                          :items-per-page="5"
                          class="elevation-0 transparent"
                          disable-items-per-page
                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[5]
                                          }"
                          no-data-text="Nessun appuntamento in calendario">
                <template v-slot:item.badge>
                    <v-badge dot color="red" />
                </template>
                <template v-slot:item.dataInizio="{ item }">
                    <span class="text-subtitle" v-text="localData(item.dataInizio)" />
                </template>
                <template v-slot:item.ore="{ item }">
                    <span v-text="item.oraInizio" />
                    <br />
                    <span v-text="item.oraFine" />
                </template>
                <template v-slot:item.descrizione="{ item }">
                    <span v-text="item.testoAppuntamento" />
                    <br />
                    <span v-text="item.partecipanti" />
                </template>
                <template v-slot:item.apri="{ item }">
                    <v-btn icon @click="apriCalendario(item)" class="ml-2">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>

    </v-card>
</template>



<script>
    import { callService, gLocalData, gLocalDateTime } from '@/modules/utilities.js'
    import { bus } from '@/main.js'
    export default {
        
        data: () => ({
            loading: false,
            hCard: 450,
            listaAppuntamenti: [],
            appuntamento:{},
            headers: [
                { text: '', value: 'badge'},
                { text: 'Data', value: 'dataInizio' },
                { text: 'Ore', value: 'ore' },
                { text: 'Descrizione', value: 'descrizione' },
                { text: '', value: 'apri' },
            ],
            timer:null
        }),
        methods: {
            cancelAutoUpdate() {
                clearInterval(this.timer);
            },
            localDateTime(v) {
                return gLocalDateTime(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            apriCalendario(item) {
                this.$router.replace({ name: "calendario" }).catch(() => { });
                this.$nextTick(() => {
                    bus.$emit("calendario-setdate", { dataInizio: item.dataInizio });
                });
            },
            async internalCreated() {
                this.loading = true;
                let param = [this.$store.state.utente.operatore.id];
                this.listaAppuntamenti = await callService("api/dashboard/appuntamentiConferma", param);
                this.loading = false;

                //timer di refresh lo imposto una sola volta altrimenti va in loop

                if (!this.timer) this.timer = setInterval(this.internalCreated, 5*60000); //ogni 5minuti
            }
        },
        async created() {
            this.$updaterHub.$on('notifiche-changed', this.internalCreated);
            await this.internalCreated();
        },
        beforeDestroy() {
            this.cancelAutoUpdate();
            this.$updaterHub.$off('notifiche-changed', this.internalCreated);
        }    
    }</script>

