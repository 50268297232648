<template>
<v-dialog v-model="dialogAP" persistent
                max-width="800">
        <v-stepper v-model="ap">
            <v-stepper-header>
                <v-stepper-step :complete="ap > 1"
                                step="1">
                    Dettagli Addebito
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="ap > 2"
                                step="2">
                    Riepilogo
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="ap > 3"
                                step="3">
                    Esito
                </v-stepper-step>
            </v-stepper-header>
            
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-container class="mb-12">
                        <v-form ref="formAP1"
                                v-model="validAP1">
                            <v-row>

                                <v-col cols="10" offset="1">
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="descrizioneAddebito" class="mn-4"
                                                          label="Descrizione addebito"
                                                          outlined
                                                          :rules="[v=> (!!v && v.length>0) || 'inserire la descrizione']"
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col v-if="isStudioPro">
                                            <v-select v-model="idProfessionistaAddebito"
                                                      :items="listaOperatoriProfessionisti"
                                                      item-value="professionista.id"
                                                      item-text="denominazioneNormalizzata"
                                                      label="Professionista di riferimento"
                                                      :rules="[v=> !!v || 'indicare il professionista']"
                                                      outlined
                                                      dense></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="dataAddebito"
                                                          label="Data addebito"
                                                          outlined
                                                          :rules="[v=> (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                          hint="gg/mm/aaaa"
                                                          persistent-hint
                                                          clearable
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                        <v-col>
                                            <p style="font-size:small">
                                                &nbsp;&nbsp;&nbsp;<strong>importo addebito: {{importoEuro(importoAddebito)}}</strong><br />
                                                &nbsp;&nbsp;&nbsp;({{selezione.length}} elementi selezionati)
                                            </p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-checkbox class="ml-2" v-model="notifica"
                                                    label="notifica addebito in StudioPro App"
                                                    color="red darken-3">
                                        </v-checkbox>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <v-row>
                        <v-col cols="2">
                            <v-btn :disabled="!validAP1" color="primary" depressed
                                    @click="ap = 2">
                                Avanti
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-container class="mb-12">
                        <v-row>
                            <v-col cols="6">
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{descrizioneAddebito}}</v-list-item-title>
                                        <v-list-item-subtitle>descrizione addebito</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-calendar</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{dataAddebito}}</v-list-item-title>
                                        <v-list-item-subtitle>data addebito</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-cash</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{importoAddebitoEuro}}</v-list-item-title>
                                        <v-list-item-subtitle>importo addebito</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="6">
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-list-box-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{selezione.length}}</v-list-item-title>
                                        <v-list-item-subtitle>Addebiti in lavorazione selezionati</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="isStudioPro" two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-tooltip-account</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6">{{descrizioneProfessionista}}</v-list-item-title>
                                        <v-list-item-subtitle>Professionista di riferimento</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-icon>
                                        <v-icon>mdi-bell-badge-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="notifica" class="text-h6">Notifica da inviare</v-list-item-title>
                                        <v-list-item-title v-else class="text-h6">Notifica da non inviare</v-list-item-title>
                                        <v-list-item-subtitle>StudioPro App</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-row>
                        <v-col cols="2">
                            <v-btn color="primary"
                                    @click="ap = 1">
                                Indietro
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn color="success" depressed
                                    @click="sendAP">
                                Addebita movimento aggregato
                                <v-icon right>
                                    mdi-send
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi" color="warning">
                                Annulla
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-container class="mb-12" align="center">
                        <v-col cols="6" offset="3">
                            <v-card v-if="loadingAP" color="primary"
                                    dark>
                                <v-card-text>
                                    Esecuzione Operazione...
                                    <v-progress-linear indeterminate
                                                        color="white"
                                                        class="mb-0">
                                    </v-progress-linear>
                                </v-card-text>
                            </v-card>
                            <v-card v-if="!loadingAP && esitoAPOK" color="success"
                                    dark>
                                <v-card-text>
                                    <v-icon dark>
                                        mdi-checkbox-marked-circle
                                    </v-icon>
                                    Operazione eseguita correttamente
                                </v-card-text>
                            </v-card>
                            <v-card v-if="!loadingAP && !esitoAPOK" color="error"
                                    dark>
                                <v-card-text>
                                    <v-icon dark>
                                        mdi-alert-circle
                                    </v-icon>
                                    Errore durante l'esecuzione dell'operazione
                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-container>

                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="2">
                            <v-btn @click="chiudi"
                                    color="warning"
                                    :disabled="loadingAP">
                                Chiudi
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-dialog>
</template>


<script>

    import { callService, callPost, euro, gLocalData, gCheckData, gServerData } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
        props: ['type'],
        data: () => ({
            selezione: [],            
            ap: 1,
            dialogAP: false,
            validAP1: false,
            loadingAP: false,
            esitoAPOK: false,
            idTipoConsulenza: '',
            tipiConsulenza: [],
            idTipoServizio: '',
            idProfessionistaAddebito: '',
            tipiServizioEstesi: [],
            descrizioneAddebito: '',
            dataAddebito: '',
            importoAddebito: 0,
            periodi: [],
            listamodelli: [],
            listaoperatori: [],
            listascadenze: [],
            listaDoc: [],
            idModello: '',
            idScadenza: '',
            idOperatore: '',
            importoScadenza: 0,
            periodiConnessi: [],
            idPeriodo: '',
            numeroEdit: 0,
            lockImporto: false,
            notifica: true,
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },
            operatore: { id: '' },
            professionista: { id: '' },
            regimeIVA: { id: '' },
            regimeContabile: { id: '' },
            tipoCliente: { id: '' },
            fido: '',
            statoCliente: '1'
        }),
        methods: {
            localData(v) {
                return gLocalData(v);
            },
            importoEuro(v) {
                return euro(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
            async stepAP1() {
                this.loadingAP = true;
                let param;
                if (this.selezione.length > 1) {
                    param = [this.idTipoConsulenza];
                }
                else {
                    param = [this.idTipoConsulenza, this.selezione[0].cliente.id];
                }

                this.tipiServizioEstesi = await callService('api/tiposervizio/spot', param);
                this.ap = 2;
                this.loadingAP = false;
            },
            async sendAP() {
                this.loadingAP = true;
                this.ap = 3;

                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    data: this.serverData(this.dataAddebito),
                    importo: this.importoAddebito,
                    descrizione: this.descrizioneAddebito,
                    ListaAddebitiP: this.selezione,
                    idProfessionista: this.idProfessionistaAddebito,
                    notifica: this.notifica
                };

                this.esitoAPOK = await callPost('api/addebito/addebitoprogressivo', param);
                if (this.esitoAPOK) {
                    bus.$emit('cliente-changed', { lista: [this.selezione[0].cliente.id], sessionID: "-1" });
                }
                this.loadingAP = false;
            },
            viewAP(selezione, type) {
                if(type!=this.type) return;
                this.selezione = selezione;
                var arrimp = this.selezione.map(ce => ce.importo);
                //alert(JSON.stringify(arrimp));
                const sum = arrimp.reduce((partialSum, a) => partialSum + a, 0);
                //const sum = arrimp.reduce(this.aggiungi, 0);
                //alert(sum);
                this.dialogAP = true;
                this.ap = 1;
                this.idTipoConsulenza = null;
                this.idTipoServizio = null;
                this.loadingAP = false;
                this.$nextTick(() => {
                    this.$refs.formAP1.resetValidation();
                    this.$refs.formAP1.reset();
                    this.$nextTick(() => {
                        this.notifica = true;
                        this.dataAddebito = this.localData(new Date());
                        this.importoAddebito = sum;
                        this.idProfessionistaAddebito = this.selezione[0].cliente.professionista.id;
                    });
                });
            },
            chiudi() {
                this.dialogAP = false;
            },            
            setImporto(tariffa) {
                this.lockImporto = tariffa != null;

                if (tariffa == null) this.importoAddebito = null;
                else this.importoAddebito = tariffa.importo;
            },
        },
        computed: {
            isLavoro() {
                return this.$store.state.lplApp.isLavoro;
            },
            isContabilita() {
                return this.$store.state.lplApp.isContabilita;
            },
            isPulizia() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isPulizia;
            },
            isManutenzione() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isManutenzione;
            },
            isChiamata() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isChiamata;
            },
            isServicePro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isPulizia || this.$store.state.lplApp.isChiamata || this.$store.state.lplApp.isManutenzione);
            },
            isStudioPro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isLavoro || this.$store.state.lplApp.isContabilita);
            },
            labelAP() {
                return "Addebito Spesa (" + this.selezione.length + ")";
            },
            importoAddebitoEuro() {
                return euro(this.importoAddebito);
            },
            importoScadenzaEuro() {
                return euro(this.importoScadenza);
            },
            descrizioneTipoServizio() {
                let ts = this.tipiServizioEstesi.find(x => x.tipoServizio.id === this.idTipoServizio);
                if (ts) return ts.tipoServizio.descrizione;
                else return "";
            },
            descrizioneTipoConsulenza() {
                let tc = this.tipiConsulenza.find(x => x.id === this.idTipoConsulenza);
                if (tc) return tc.descrizione;
                else return "";
            },
            descrizionePeriodo() {
                let p = this.periodi.find(x => x.id === this.idPeriodo);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneModello() {
                let p = this.listamodelli.find(x => x.id === this.idModello);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneScadenza() {
                let p = this.listascadenze.find(x => x.id === this.idScadenza);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneOperatore() {
                let p = this.listaoperatori.find(x => x.id === this.idOperatore);
                if (p) return p.denominazioneNormalizzata;
                else return "";
            },
            descrizioneProfessionista() {
                let p = this.listaOperatoriProfessionisti.find(x => x.professionista.id === this.idProfessionistaAddebito);
                if (p) return p.denominazioneNormalizzata;
                else return "";
            },
            bpNuovi() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero > 0).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpLock() {
                let n = this.clientiTariffa.filter(c => c.lock).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpLiberi() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero == 0).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpAbilitaSave() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero > 0).length;
                return n > 0;
            },
            //aggiungi(accumulator, a) {
            //   return accumulator + a;
            //}
        },        
        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.theme = theme;

            this.tipiConsulenza = this.$store.state.listaTipoConsulenza;
            this.clientiEstesi = this.$store.state.clientiEstesi;
            //console.log(JSON.stringify(this.clientiEstesi));
            this.listaOperatore = this.$store.state.listaOperatore;
            this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;
            this.listaTipoRegimeIVA = this.$store.state.listaTipoRegimeIVA;
            this.listaTipoRegimeContabile = this.$store.state.listaTipoRegimeContabile;
            this.listaTipoCliente = this.$store.state.listaTipoCliente;

            bus.$on('dialog-show-ap', this.viewAP)

            this.loading = false;
        },    
        beforeDestroy() {
            bus.$off('dialog-show-ap', this.viewAP)
        }
    }</script>


    
