<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="1">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="teal"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="nuovo">
                                                    <v-icon class="ma-0">mdi-account-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Nuovo Contatto in Rubrica</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-text-field v-model="searchText"
                                                      append-icon="mdi-magnify"
                                                      label="Ricerca un contatto per codice cliente, nominativo, piva, cf, etichetta, località"
                                                      single-line
                                                      :hint="hintSelezione"
                                                      persistent-hint
                                                      outlined
                                                      clearable
                                                      @input="changeSearch"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-tooltip v-if="!fileExcel" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="brown"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       :loading="loadingExcel"
                                                       :disabled="selezione.length==0"
                                                       @click="listaExcel">
                                                    <v-icon>mdi-microsoft-excel</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Genera lista Excel dalla selezione</span>
                                        </v-tooltip>
                                        <v-tooltip v-if="fileExcel" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn :loading="loadingExcel"
                                                       icon
                                                       color="error"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="scaricaExcel">
                                                    <v-icon>mdi-magnify</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Scarica file Excel</span>
                                        </v-tooltip>

                                    </v-col>
                                </v-row>
                                <v-row class="mt-n4">
                                    <v-col cols="12">
                                        <v-data-table v-model="selezione"
                                                      :headers="headers"
                                                      :items="clientiEstesi"
                                                      item-key="cliente.id"
                                                      ref="tabellaClienti"
                                                      :loading="loading"
                                                      progress="78"
                                                      :items-per-page="nPag"
                                                      class="elevation-1"
                                                      :item-class="coloraStatus"
                                                      :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                                                      show-select
                                                      @toggle-select-all="toggleAll"
                                                      @item-selected="toggleSelect"
                                                      @current-items="filterItems"
                                                      @pagination="pagination"
                                                      :single-select="false"
                                                      :search="search"
                                                      :custom-filter="customFilter"
                                                      loading-text="Caricamento dati..."
                                                      no-data-text="Nessun contatto presente in archivio"
                                                      no-results-text="Nessun contatto corrispodende ai criteri di ricerca">
                                            <template v-slot:item.azioni="{item}">
                                                <v-row>
                                                    <v-col class="mx-n4">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn class="mx-3" icon @click="anagrafica(item.cliente)"
                                                                       v-bind="attrs"
                                                                       v-on="on">
                                                                    <v-icon color="blue darken-2">
                                                                        mdi-card-account-details-outline
                                                                    </v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Anagrafica Contatto</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template v-slot:header.cliente.denominazioneNormalizzata>
                                                Nominativo
                                                <br />
                                                P.IVA/C.Fiscale
                                            </template>
                                            <template v-slot:item.cliente.denominazioneNormalizzata="{ item }">
                                                <span class="text-subtitle font-weight-medium" v-text="item.cliente.denominazioneNormalizzata" />
                                                <br />
                                                <span v-if="item.cliente.partitaIVA" class="font-weight-light" v-text="item.cliente.codiceFiscale" />
                                                <span v-if="item.cliente.codiceFiscale" class="font-weight-light" v-text="item.cliente.codiceFiscale" />
                                                <span v-else class="font-weight-light" v-text="item.cliente.codiceFiscaleLR" />
                                            </template>
                                            <template v-slot:item.listaRecapitiTel="{ item }">
                                                <v-chip small dark color="cyan"
                                                        v-for="tel in item.telList"
                                                        :key="tel"
                                                        v-text="tel">
                                                </v-chip>
                                            </template>
                                            <template v-slot:item.listaRecapitiMail="{ item }">
                                                <v-chip small dark color="green"
                                                        v-for="mail in item.mailList"
                                                        :key="mail"
                                                        v-text="mail">
                                                </v-chip>
                                            </template>
                                            <template v-slot:item.listaRecapitiAltro="{ item }">
                                                <v-chip small dark color="orange"
                                                        v-for="altro in item.altroList"
                                                        :key="altro"
                                                        v-text="altro">
                                                </v-chip>
                                            </template>
                                            <template v-slot:item.cliente.isContatto="{ item }">
                                                <v-simple-checkbox v-model="!item.cliente.isContatto"
                                                                   disabled>
                                                </v-simple-checkbox>
                                            </template>
                                            <template v-slot:item.cliente.tags="{ item }">
                                                <v-chip small dark color="gray"
                                                        v-for="tag in item.cliente.tagList"
                                                        :key="tag"
                                                        v-text="tag">
                                                </v-chip>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>

    </v-container>

</template>

<script>import { callService, callPost, euro, gLocalData, gCheckData, gServerData } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
        data: () => ({
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            dialogCliente: false,
            selezione: [],
            filtro: [],
            statoAll: false,
            loading: true,
            showError: false,
            viewMenuCliente: false,
            x: 0,
            y: 0,
            search: '',
            searchState: [],
            searchText: '',
            searchBP: '',
            cliente: [],
            clientiEstesi: [],
            headers: [
                { text: 'Azioni', value: 'azioni', sortable: false, width: '5%', align:'center'  },
                { text: 'Codice', value: 'cliente.codice', width: '5%' },
                { text: 'Nominativo', value: 'cliente.denominazioneNormalizzata', width:'45%'},
                { text: 'Cliente', value: 'cliente.isContatto', width: '10%' },
                { text: 'Telefono', value: 'listaRecapitiTel', width: '10%' },
                { text: 'Email', value: 'listaRecapitiMail', width: '10%' },
                { text: 'Altro', value: 'listaRecapitiAltro', width: '10%' },
                { text: 'Etichette', value: 'cliente.tags', width: '10%' },
            ],
            nPag: 8,
            theme: '',
            oggetto: '',
            testo: '',
            idTipoServizio: '',
            tipiServizioEstesi: [],
            descrizioneAddebito: '',
            dataAddebito: '',
            importoAddebito: 0,
            periodi: [],
            listamodelli: [],
            listaoperatori: [],
            listascadenze: [],
            listaDoc: [],
            idModello: '',
            idScadenza: '',
            idOperatore: '',
            importoScadenza: 0,
            periodiConnessi: [],
            idPeriodo: '',
            loadingExcel: false,
            fileExcel:'',
            dataScadenza: '',
            lockImporto: false,
            numeroEdit: 0,
            clientiTariffa: [],
            conteggioClienti: [],
            listaClientiNoTariffa: [],
            listaClientiTariffaAddebitata: [],
            listaClientiNoAddebito: [],
            listaClientiOk: [],
            notifica: true,
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },
            operatore: { id: '' },
            professionista: { id: '' },
            regimeIVA: { id: '' },
            regimeContabile: { id: '' },
            tipoCliente: { id: '' },
            fido: '',
        }),
        methods: {
            localData(v) {
                return gLocalData(v);
            },
            importoEuro(v) {
                return euro(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
            async listaExcel() {
                this.loadingExcel = true;
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idAnnoContabile: this.$store.state.idAnnoContabile,
                    listaClienti: this.selezione.map(ce => ce.cliente),
                };
                let outputPdf = await callPost('api/excel/listacontatti', param);
                if (outputPdf) {
                    this.fileExcel = outputPdf.fileOutput;
                }
                else this.snackError = true;
                this.loadingExcel = false;
            },
            scaricaExcel() {
                //alert(this.filePdf);
                window.open(this.fileExcel);
                this.fileExcel = '';
            },
            async onClienteChanged({ lista, sessionID }) {
                
                if (sessionID == this.$store.state.sessionID) return;

                for (var i = 0; i < lista.length; i++) {

                    let param = [lista[i]];
                    let clientiEstesiDelta = await callService('api/cliente/listaContattiEstesa', param);
                    let clienteEsteso = clientiEstesiDelta[0];

                    //cerco nella lista il cliente ricalcolato

                    const index = this.clientiEstesi.findIndex(ce => {
                        return ce.cliente.id === clienteEsteso.cliente.id;
                    });

                    if (index >= 0) {
                        this.clientiEstesi.splice(index, 1, clienteEsteso);
                    }
                    else {
                        this.clientiEstesi.push(clienteEsteso);
                    }

                }
            },
            coloraRiga(item) {
                if (item.colore == "grey") return "style-lock";
                return "";
            },
            coloraStatus(item) {
                //if (item.cliente.isSospeso) return "style-suspended";
                //if (item.cliente.isAddebitiSospesi) return "style-fee-suspended";
                //if (item.cliente.isCancellato) return "style-deleted";
                return "";
            },
            filterItems(v) {
                this.$nextTick(() => {
                    this.filtro = this.$refs.tabellaClienti.$children[0].filteredItems;
                });
            },
            saveNumero(item) {
                if (item.lockNumero && this.numeroEdit && this.numeroEdit != 1 && this.numeroEdit != 0) return;
                item.numero = Number(this.numeroEdit);
                item.totale = item.numero * item.tariffa.importo;
                if (item.numero > 0) item.colore = "green";
                if (item.numero == 0) item.colore = "blue";
            },
            anagrafica(cliente) {
                this.cliente = JSON.parse(JSON.stringify(cliente));
                bus.$emit('dialog-show-cliente', this.cliente, true);
                //this.$router.replace({ name: "cliente", query: { id: cliente.id } });
            },
            nuovo() {
                this.cliente.id = "0";
                bus.$emit('dialog-show-cliente', this.cliente, true);
                //this.$router.replace({ name: "cliente", query: { id: "0" } });
            },
            toggleAll() {
                this.statoAll = !this.statoAll;
                this.$store.dispatch('setStatoAll', this.statoAll);

                if (this.statoAll) {
                    this.selezione = this.filtro;
                }
                else {
                    this.selezione = [];
                };
                this.$store.dispatch('setSelezione', this.selezione);
            },
            toggleSelect() {
                this.$nextTick(() => {
                    this.$store.dispatch('setSelezione', this.selezione);
                });
            },
            setImporto(tariffa) {
                this.lockImporto = tariffa != null;

                if (tariffa == null) this.importoAddebito = null;
                else this.importoAddebito = tariffa.importo;
            },
            pagination(pag) {
                let nPag = pag.itemsPerPage;
                this.$cookie.set('rubrica-pagination', nPag.toString(), { expires: '1Y' });
            },
            customFilter(value, search, item) {
                let ok = true;
                if (ok && this.searchText) { //codice/dnz/fiscale in OR
                    let src = this.searchText.toLowerCase();
                    src = src.replace(/\s+/g, ' '); //elimino i doppi spazi interni
                    src = src.trim();
                    var tokens = src.split(" ");
                    var i=0;
                    //console.log("*"+src+"*");
                    while (ok && i<tokens.length) {
                        let srcToken = tokens[i];
                        let ok1 = item.cliente.denominazioneNormalizzata && item.cliente.denominazioneNormalizzata.toLowerCase().indexOf(srcToken) >= 0;
                        let ok2 = !ok1 && item.cliente.denominazioneNormalizzataRL && item.cliente.denominazioneNormalizzataRL.toLowerCase().indexOf(srcToken) >= 0;
                        let ok3 = !ok2 && item.cliente.codice && item.cliente.codice.toLowerCase().indexOf(srcToken) >= 0;
                        let ok4 = !ok3 && item.cliente.codiceFiscale && item.cliente.codiceFiscale.toLowerCase().indexOf(srcToken) >= 0;
                        let ok5 = !ok4 && item.cliente.codiceFiscaleLR && item.cliente.codiceFiscaleLR.toLowerCase().indexOf(srcToken) >= 0;
                        let ok6 = !ok5 && item.cliente.tags && item.cliente.tags.toLowerCase().indexOf(srcToken) >= 0;
                        let ok7 = !ok6 && item.mailList && item.listaRecapitiMail.toLowerCase().indexOf(srcToken) >= 0;
                        let ok8 = !ok7 && item.mailList && item.listaRecapitiTel.toLowerCase().indexOf(srcToken) >= 0;
                        let ok9 = !ok8 && item.mailList && item.listaRecapitiAltro.toLowerCase().indexOf(srcToken) >= 0;
                        let ok10 = !ok9 && item.cliente.partitaIVA && item.cliente.partitaIVA.toLowerCase().indexOf(srcToken) >= 0;


                        ok = ok1 || ok2 || ok3 || ok4 || ok5 || ok6 || ok7 || ok8 || ok9 || ok10;
                        i++;
                    }                    
                }

                //filtri su combo non presenti in rubrica
                //if (ok && this.operatore.id) {
                //    ok = item.operatori.findIndex(o => o.id == this.operatore.id) >= 0;
                //}
                //if (ok && this.professionista.id) {
                //    ok = item.cliente.professionista.id == this.professionista.id;
                //}
                //if (ok && this.regimeIVA.id) {
                //    ok = (item.cliente.tipoRegimeIVA && item.cliente.tipoRegimeIVA.id == this.regimeIVA.id);
                //}
                //if (ok && this.regimeContabile.id) {
                //    ok = (item.cliente.tipoRegimeContabile && item.cliente.tipoRegimeContabile.id == this.regimeContabile.id);
                //}
                //if (ok && this.tipoCliente.id) {
                //    ok = (item.cliente.tipoCliente && item.cliente.tipoCliente.id == this.tipoCliente.id);
                //}
                //if (ok && this.fido) {
                //    ok = this.fido == item.fido;
                //}
                //if (ok && this.statuspec) {
                //    ok = this.statuspec == item.statuspec;
                //}

                return ok;
            },
            changeSearch() {
                this.searchState =
                    [this.searchText,
                    this.operatore.id,       
                    this.professionista.id, 
                    this.regimeIVA.id,     
                    this.regimeContabile.id, 
                    this.tipoCliente.id, 
                    this.fido,  
                    ];
                    this.search=this.searchState.toString();
                    this.writeState(); //memorizzo lo stato della ricerca
            },
            visualizzaClienti(lista) {
                if (!lista) return;
                bus.$emit('msg-list-open', { list: lista.map(c => ({ ...c, desc: c.codice + " - " + c.denominazioneNormalizzata })), title: "Elenco Clienti" });
            },
            readState() {
                this.selezione = this.$store.state.selezione;
                this.statoAll = this.$store.state.statoAll;
                this.searchState= this.$store.state.searchState;
                this.searchText = this.searchState[0];
                this.operatore.id= this.searchState[1];       
                this.professionista.id= this.searchState[2]; 
                this.regimeIVA.id= this.searchState[3];     
                this.regimeContabile.id= this.searchState[4];
                this.tipoCliente.id = this.searchState[5];
                this.fido= this.searchState[6];        
                this.statoCliente= this.searchState[7];
                this.search=this.searchState.toString();
            },
            writeState() {

                //console.log(this.search);

                this.$store.dispatch('setStatoAll', false);
                this.$store.dispatch('setSelezione', this.selezione);
                this.$store.dispatch('setSearchState', this.searchState);

            }
        },
        computed: {
            isLavoro() {
                return this.$store.state.lplApp.isLavoro;
            },
            isContabilita(){
                return this.$store.state.lplApp.isContabilita;
            },
            hintSelezione() {
                if (this.selezione.length == 1) return "1 cliente selezionato su " + this.filtro.length;
                if (this.selezione.length > 1) return this.selezione.length + " clienti selezionati su " + this.filtro.length;
                return "";
            },
            importoAddebitoEuro() {
                return euro(this.importoAddebito);
            },
            importoScadenzaEuro() {
                return euro(this.importoScadenza);
            },
            descrizioneTipoServizio() {
                let ts = this.tipiServizioEstesi.find(x => x.tipoServizio.id === this.idTipoServizio);
                if (ts) return ts.tipoServizio.descrizione;
                else return "";
            },
            descrizionePeriodo() {
                let p = this.periodi.find(x => x.id === this.idPeriodo);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneModello() {
                let p = this.listamodelli.find(x => x.id === this.idModello);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneScadenza() {
                let p = this.listascadenze.find(x => x.id === this.idScadenza);
                if (p) return p.descrizione;
                else return "";
            },
            descrizioneOperatore() {
                let p = this.listaoperatori.find(x => x.id === this.idOperatore);
                if (p) return p.denominazioneNormalizzata;
                else return "";
            },
            bpNuovi() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero > 0).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpLock() {
                let n = this.clientiTariffa.filter(c => c.lock).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpLiberi() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero == 0).length;
                if (n == 0 || n > 1) return n + " Clienti";
                return "1 Cliente";
            },
            bpAbilitaSave() {
                let n = this.clientiTariffa.filter(c => !c.lock && c.numero > 0).length;
                return n > 0;
            }
        },
        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.theme = theme;
            this.clientiEstesi = this.$store.state.contattiEstesi;
            ////console.log(JSON.stringify(this.clientiEstesi));
            this.$updaterHub.$on('cliente-changed', this.onClienteChanged);
            bus.$on('cliente-changed', this.onClienteChanged);

            this.nPag = Number(this.$cookie.get('rubrica-pagination')); //leggo il coockie della paginazione
            this.readState();

            this.loading = false;
        },
        beforeDestroy() {
            this.$updaterHub.$off('cliente-changed', this.onClienteChanged)
            bus.$off('cliente-changed', this.onClienteChanged)
        }
    }</script>

<style>
    .style-lock {
        color: grey
    }

    .style-suspended {
        background-color: orange
    }

    .style-fee-suspended {
        background-color: yellow
    }

    .style-deleted {
        background-color: red
    }
</style>


    
