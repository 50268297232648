<template>
    <v-card class="ma-2" :loading="loading" color="blue darken-2" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-clock-edit-outline
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Attività su Clienti: {{listaAttivita.length}}
            </span>
        </v-card-title>
        <v-card-text>
            <v-data-table :headers="headers"
                          :items="listaAttivita"
                          :items-per-page="5"
                          class="elevation-0 transparent"
                          disable-items-per-page
                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[5]
                                          }"
                          no-data-text="Nessun task aperto">
                <template v-slot:item.dataScadenza="{ item }">
                    <span class="text-subtitle" v-text="localData(item.dataScadenza)" />
                </template>
                <template v-slot:item.pratica="{ item }">
                    <span v-text="item.pratica.tipoPratica.descrizione" />
                    <br />
                    <span v-if="item.pratica.cliente" class="font-weight-light" v-text="item.pratica.cliente.denominazioneNormalizzata" />
                    <span v-else class="font-weight-light" v-text="item.pratica.descrizione" />
                </template>
                <template v-slot:item.apri="{ item }">
                    <v-btn icon @click="apriScheda(item)" class="ml-2">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>
        <scheda-dialog />
    </v-card>
</template>



<script>
    import { callService, gLocalData } from '@/modules/utilities.js'
    import { bus } from '@/main.js'
    import SchedaDialog from '@/views/components/SchedaDialog.vue'

    export default {
         components: {
            SchedaDialog
        },
        data: () => ({
            loading: false,
            hCard: 450,
            listaAttivita: [],
            pratica:{},
            headers: [
                { text: 'Cliente', value: 'cliente.denominazioneNormalizzata' },
                { text: 'Descrizione', value: 'note' },
                { text: 'Data Scadenza', value: 'dataScadenza' },
                { text: '', value: 'apri' },
            ],
        }),
        methods: {            
            localData(v) {
                return gLocalData(v);
            },
            apriScheda(item) {
                let cliente = JSON.parse(JSON.stringify(item.cliente));
                bus.$emit('dialog-show-scheda', cliente);
            },
            async onAttivitaChanged(){
                await this.internalCreated();
            },
            async internalCreated() {
                this.loading = true;
                let param = [this.$store.state.utente.operatore.id];
                this.listaAttivita = await callService("api/dashboard/listaAttivita", param);
                this.loading = false;
            }
        },
        computed: {
            
        },
        async created() {
            this.$updaterHub.$on('notifiche-changed', this.internalCreated);
            await this.internalCreated();
        },        
        beforeDestroy() {
            this.$updaterHub.$off('notifiche-changed', this.internalCreated);
        }
    }</script>

