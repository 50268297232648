<template>
    <v-dialog v-model="dialog" persistent max-width="1500px">

        <v-card :loading="loading">
            <v-card-text>
                <v-form ref="form"
                        v-model="valid"
                        class="mt-4 ml-4 mr-4"
                        lazy-validation>
                    <v-tabs>
                        <v-tab>Gestione Scadenza</v-tab>
                        <v-tab-item>
                            <v-row class="mt-4">
                                <v-col cols="4">
                                    <div v-if="!this.nuovo">
                                        <v-text-field v-model="scadenza.descrizione"
                                                      label="Descrizione"
                                                      required
                                                      outlined
                                                      disabled
                                                      dense></v-text-field>
                                    </div>
                                    <div v-else>
                                        <v-select v-model="idScadenza"
                                                  :items="listaScadenze"
                                                  item-value="id"
                                                  item-text="descrizione"
                                                  label="Tipo Scadenza"
                                                  outlined
                                                  :rules="[v=> !!v || 'selezionare la tipologia di scadenza']"
                                                  dense></v-select>
                                    </div>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="scadenza.dataScadenza"
                                                  label="Data scadenza"
                                                  outlined
                                                  :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                  hint="gg/mm/aaaa"
                                                  persistent-hint
                                                  clearable
                                                  dense></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <vuetify-money v-model="scadenza.importo"
                                                   label="Importo"
                                                   outlined
                                                   dense
                                                   :rules="[v => !!v || 'inserire importo', v => (scadenza.importo && scadenza.importo>0) || 'inserire importo >0']"
                                                   :options="moneyOptions">
                                    </vuetify-money>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-select v-model="scadenza.cliente.id"
                                              :items="listaCliente"
                                              item-value="cliente.id"
                                              item-text="cliente.denominazioneNormalizzata"
                                              label="Cliente"
                                              :rules="[v=> !!v || 'indicare il cliente']"
                                              :disabled="!this.nuovo"
                                              outlined
                                              dense></v-select>
                                </v-col>

                                <v-col cols="5">
                                    <v-select v-model="scadenza.operatore.id"
                                              :items="listaOperatore"
                                              item-value="id"
                                              item-text="denominazioneNormalizzata"
                                              label="Operatore"
                                              :rules="[v=> !!v || 'indicare operatore']"
                                              outlined
                                              dense></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="3">
                                    <v-text-field v-model="scadenza.dataInvioRichiesta"
                                                  label="Data invio richiesta"
                                                  outlined
                                                  :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                  hint="gg/mm/aaaa"
                                                  persistent-hint
                                                  clearable
                                                  dense></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field v-model="scadenza.dataRisposta"
                                                  label="Data risposta cliente"
                                                  outlined
                                                  :rules="[v=> v==null || v.length==0 || (/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) && checkData(v)) || 'gg/mm/aaaa']"
                                                  hint="gg/mm/aaaa"
                                                  persistent-hint
                                                  clearable
                                                  dense></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-checkbox class="mt-0"
                                                v-model="scadenza.isAutorizzata"
                                                color="green"
                                                :label="descrizioneRisposta(scadenza.isAutorizzata)">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                    <v-checkbox class="mt-0"
                                                v-model="scadenza.isRispostaApp"
                                                color="red"
                                                :label="descrizioneApp(scadenza.isRispostaApp)">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                    <v-checkbox class="mt-0"
                                                v-model="scadenza.isChiusa"
                                                color="blue"
                                                :label="descrizioneChiusa(scadenza.isChiusa)">
                                    </v-checkbox>
                                </v-col>
                            </v-row>

                        </v-tab-item>


                        <v-spacer></v-spacer>
                        <v-btn class="mt-2 mr-2" color="teal" :dark="valid"
                               @click="salva"
                               :loading="loadingSalva"
                               :disabled="!valid">
                            Salva
                        </v-btn>
                        <v-btn class="mt-2 mr-2" color="orange darken-2" dark
                               :loading="loadingAnnulla"
                               @click="annulla">
                            Annulla modifiche
                        </v-btn>
                        <v-btn class="mt-2 mr-2"
                               color="blue darken-2" dark
                               @click.stop="dialog = false">
                            Chiudi
                        </v-btn>
                    </v-tabs>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-dialog>
</template>

<script>

    import { callService, callPost, Snack, euro, gLocalData, gCheckData, gServerData } from '@/modules/utilities.js'
    import { bus } from '@/main.js'


    export default {
        data: () => ({
            dialog: false,
            valid: true,
            nuovo: false,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            dialogDelete: false,
            loading: false,
            loadingSalva: false,
            loadingAnnulla: false,
            listaOperatore: [],
            listaScadenze: [],
            idScadenza: '',
            listaCliente: [],
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            }, 
            scadenza: {
                id:'',
                importo: 0,
                cliente: {
                    id:''
                },
                operatore: {
                    id: ''
                }
            }
        }),
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" });
            }            
        },
        computed: {
            descrizioneScadenza() {
                let p = this.listaScadenze.find(x => x.id === this.idScadenza);
                if (p) return p.descrizione;
                else return "";
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout');
                this.$store.dispatch('setUtente', null);
                this.$router.replace({ name: "login" });
            },
            checkData(d) {
                return gCheckData(d);
            },
            localData(v) {
                return gLocalData(v);
            },
            serverData(stringDate) {
                return gServerData(stringDate);
            },
            validate() {
                this.$refs.form.validate()
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },
            descrizioneChiusa(v) {
                if (v == false) return "In lavorazione";
                return "Completata";
            },
            descrizioneApp(v) {
                if (v == false) return "Risposta via Mail/Telefono";
                return "Risposta via App StudioPro";
            },
            descrizioneRisposta(v) {
                if (v == false) return "Non autorizzata dal cliente";
                return "Autorizzata dal cliente";
            },
            async annulla() {
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);    
                this.loadingAnnulla = false;
            },            
            async salva() {

                if (!this.$refs.form.validate()) return;

                let scadenza = JSON.parse(JSON.stringify(this.scadenza));
                scadenza.dataScadenza = this.serverData(scadenza.dataScadenza);
                scadenza.dataInvioRichiesta = this.serverData(scadenza.dataInvioRichiesta);
                scadenza.dataRisposta = this.serverData(scadenza.dataRisposta);
                scadenza.descrizione = this.descrizioneScadenza;
                //alert(this.descrizioneScadenza);
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    scadenza: scadenza
                };

                this.loadingSalva = true;
                let sca = await callPost('api/scadenze/save', paramJson);


                if (sca) {
                    this.scadenza.id = sca.id;
                    this.nuovo = false;
                    bus.$emit('scadenza-changed', { lista: [this.scadenza.id], sessionID: "-1" });
                    bus.$emit('scadenza-snack-succes',{});
                    this.loadingSalva = false;  
                    this.dialog = false;
                }
                else {
                    await this.internalCreated(Snack.error);
                    this.loadingSalva = false;   
                }

            },
            async internalCreated(snack) {

                this.listaOperatore = this.$store.state.listaOperatore;
                this.listaCliente = this.$store.state.clientiEstesi;
                this.listaScadenze = this.$store.state.listaTipoScadenza;

                let param = [];
                param = [this.scadenza.id];

                let scadenza = await callService("api/scadenze/scadenza", param);
                if (this.nuovo) {
                    scadenza.dataScadenza = Date.now();
                }   

                this.scadenza = scadenza;

                scadenza.dataScadenza = this.localData(scadenza.dataScadenza);
                scadenza.dataInvioRichiesta = this.localData(scadenza.dataInvioRichiesta);
                scadenza.dataRisposta = this.localData(scadenza.dataRisposta);

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            }    
        },
        async created() {           

            var vm = this;

            bus.$on('dialog-show-scadenza', async function (scadenza) {
                vm.dialog = true;
                vm.loading = true;
                vm.nuovo = scadenza.id == "0";
                vm.scadenza.id = scadenza.id;
                await vm.internalCreated(Snack.nothing);
                vm.loading = false;
            });   
        },
        beforeDestroy() {
            bus.$off('dialog-show-scadenza');
        }
    }

</script>

