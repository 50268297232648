<template>

    <v-dialog v-model="dialog" persistent max-width="1400px">

        <v-card :loading="loading">
            <v-card-text>
                <v-form ref="form" :disabled="pratica.isChiusa"
                        v-model="valid"
                        lazy-validation>
                    <v-tabs>
                        <v-tab>{{headerDialog}}</v-tab>
                        <v-tab-item>
                            <v-row class="mt-4">
                                <v-col>
                                    <v-row>
                                        <v-col cols="2">
                                            <v-select v-model="pratica.tipoPratica.id"
                                                      :items="tipiPraticaEstesi"
                                                      item-value="tipoPratica.id"
                                                      item-text="tipoPratica.descrizione"
                                                      label="Tipo Servizio"
                                                      :rules="[v=> !!v || 'indicare il tipo di servizio']"
                                                      :disabled="!this.nuovo"
                                                      outlined
                                                      @change="changeTipoPratica"
                                                      dense>
                                            </v-select>
                                        </v-col>                                        
                                        <v-col cols="2">
                                            <v-select v-model="pratica.operatoreCreazione.id"
                                                      :items="listaOperatore"
                                                      item-value="id"
                                                      item-text="denominazioneNormalizzata"
                                                      label="Servizio Richiesta da"
                                                      :rules="[v=> !!v || 'indicare un operatore']"
                                                      :disabled="!this.nuovo || !$store.state.utente.isReception"
                                                      outlined
                                                      dense></v-select>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-text-field v-model="pratica.numeroPratica"
                                                          label="Numero Servizio"
                                                          outlined
                                                          clearable
                                                          dense></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="pratica.descrizione"
                                                          label="Descrizione Servizio"
                                                          outlined
                                                          :rules="[v=> (!!v && v.length>0) || 'inserire una descrizione']"
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="mt-n6">
                                        <v-col cols="2">
                                            <v-select v-model="pratica.oraInizio"
                                                      :items="listaOre"
                                                      item-value="id"
                                                      item-text="id"
                                                      label="Dalle"
                                                      outlined
                                                      hide-details
                                                      clearable
                                                      dense></v-select>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-select v-model="pratica.oraFine"
                                                      :items="listaOre"
                                                      item-value="id"
                                                      item-text="id"
                                                      label="Alle"
                                                      outlined
                                                      clearable
                                                      hide-details
                                                      dense></v-select>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-text-field v-model="pratica.note"
                                                          outlined
                                                          label="Note Servizio"
                                                          dense>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                 </v-col>
                            </v-row>
                            <v-row class="mt-n6">
                                <v-col>
                                    <v-data-table :headers="headersService"
                                                  :items="listaTask"
                                                  ref="tabella"
                                                  :loading="loading"
                                                  :items-per-page="8"
                                                  class="elevation-1 ma-2"
                                                  :footer-props="{
                                                        'items-per-page-text':'Pagina da',
                                                        'items-per-page-all-text': '',
                                                        'items-per-page-options':[8]
                                                      }"
                                                  loading-text="Caricamento dati..."
                                                  no-data-text="Nessun task presente in archivio"
                                                  no-results-text="Nessuna task corrispodende ai criteri di ricerca">
                                        <template slot="header.operazione">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" color="info" class="ml-2"
                                                           v-on="on"
                                                           icon @click="newTask">
                                                        <v-icon>mdi-folder-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Aggiungi Task
                                                </span>
                                            </v-tooltip>
                                        </template>
                                        <template v-slot:item.descrizione="{ item }">
                                            <v-row>
                                                <v-icon v-if="item.isFinale" color="blue">mdi-arrow-right-bottom</v-icon>
                                                <v-text-field v-model="item.descrizione"
                                                              outlined
                                                              dense
                                                              :rules="[v=> !!v || '']"
                                                              hide-details>
                                                </v-text-field>
                                            </v-row>
                                        </template>
                                        <template v-slot:item.operatore="{ item }">
                                            <v-select v-model="item.operatore.id"
                                                      :items="listaOperatore"
                                                      hide-details
                                                      item-value="id"
                                                      clearable
                                                      item-text="denominazioneNormalizzata"
                                                      outlined
                                                      dense></v-select>
                                        </template>
                                        <template v-slot:item.note="{item}">
                                            <v-row>
                                                <v-text-field v-model="item.note"
                                                              outlined
                                                              dense
                                                              hide-details>
                                                </v-text-field>
                                            </v-row>
                                        </template>
                                        <template v-slot:item.order="{ item }">
                                            <v-icon class="ma-0" @click="up(item)" color="blue">mdi-arrow-up-bold-box</v-icon>
                                            <v-icon class="ma-0" @click="down(item)" color="blue">mdi-arrow-down-bold-box</v-icon>
                                            <v-icon class="ma-0" @click="item.isFinale=false" color="blue">mdi-arrow-left-bold-box</v-icon>
                                            <v-icon class="ma-0" @click="item.isFinale=true" color="blue">mdi-arrow-right-bold-box</v-icon>
                                        </template>
                                        <template v-slot:item.operazione="{ item }">
                                            <v-tooltip bottom v-if="($store.state.utente && $store.state.utente.isGestoreStudio) || ($store.state.utente && $store.state.utente.isReception)">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn :disabled="pratica.isChiusa"
                                                           v-bind="attrs"
                                                           v-on="on"
                                                           icon @click="eliminaTask(item)"
                                                           color="red darken-3" class="ml-2">
                                                        <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>
                                                    Elimina Task
                                                </span>
                                            </v-tooltip>

                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-spacer></v-spacer>
                        <v-btn class="mt-2 mr-2" color="teal" :dark="!(!valid || pratica.isChiusa)"
                               @click="salva"
                               :loading="loadingSalva"
                               :disabled="!valid || pratica.isChiusa">
                            Salva
                        </v-btn>
                        <v-btn class="mt-2 mr-2" color="orange darken-2"
                               :loading="loadingAnnulla"
                               :disabled="pratica.isChiusa"
                               :dark="!pratica.isChiusa"
                               @click="annulla">
                            Annulla modifiche
                        </v-btn>
                        <v-btn class="mt-2 mr-2"
                               color="blue darken-2" dark
                               @click.stop="dialog = false">
                            Esci
                        </v-btn>
                    </v-tabs>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackSuccess" centered
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError" centered
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel" centered
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote" centered
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento effettuato.
        </v-snackbar>
    </v-dialog>
</template>
<script>

    import { callService, callPost, callPostMultipart,Snack, euro, gLocalData, gLocalDateTime, gCheckData, gServerData, gServer2Data } from '@/modules/utilities.js'
    import pdf from 'vue-pdf'
    import { bus } from '@/main.js'

    export default {
        components: {
            pdf
        },
        data: () => ({
            dialog: false,
            theme: false,
            valid: true,
            nuovo: false,
            copia: false,
            dialogWork: false,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            dialogDelete: false,
            loading: false,
            loadingSalva: false,
            loadingAnnulla: false,
            listaOperatore: [],
            listaTag: [],
            listaOre: [],
            listaTagIni: [
                {tag:'tag1'},
                {tag:'tag2'},
            ],
            labelInput:'',           
            headersService: [
                { text: '', value: 'order', sortable: false, width: '10%' },
                { text: 'Task', value: 'descrizione', width: '20%', align: 'left' },
                { text: 'Operatore Assegnato', value: 'operatore',width:'20%'},
                { text: 'Note', value: 'note', align: 'left', width: '45%' },                   
                { text: '', value: 'operazione', sortable: false, align: 'right' }, 
             ],
            listaTask:[],
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },
            pratica: {
                id:'',
                operatore: {
                    id:''
                },
                operatoreCreazione: {
                    id: ''
                },
                //cliente: {
                //    id: ''
                //},
                tipoPratica: {
                    id:''
                },
                //indirizzarioCliente: {
                //    id: ''
                //}
            },
            addebito: null,
            tipiPraticaEstesi: [],
            config: {
                theme: 'default'
            },
            type: ''
        }),
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" }).catch(()=>{});
            }            
        },
        computed: {
            headerDialog() {
                if(this.nuovo) return "Nuovo Servizio Contrattuale";
                return "Gestione Servizio Contrattuale";
            },
        },
        methods: {
            down(item) {
                let idx = this.listaTask.indexOf(item);
                if (idx == this.listaTask.length - 1) return;
                let temp = JSON.parse(JSON.stringify(item));
                this.listaTask.splice(idx, 1, this.listaTask[idx+1]);
                this.listaTask.splice(idx + 1, 1, temp);

            },
            up(item) {
                let idx = this.listaTask.indexOf(item);
                if (idx == 0) return;
                let temp = JSON.parse(JSON.stringify(item));
                this.listaTask.splice(idx, 1, this.listaTask[idx - 1]);
                this.listaTask.splice(idx - 1, 1, temp);

            },
            tail(s,n) {
                if(!s) return "";
                if(s.length<n) return s;
                return s.substring(0,n-3)+"...";
            },            
            importoEuro(v) {   
                return euro(v);
            },
            newTask() {
                this.listaTask.push(
                    {
                        id: new Date().getTime().toString(),
                        idTask: (this.listaTask.length+1).toString(),
                        descrizione: "nuovo task",
                        operatore: {
                            id:''
                            }
                    })
            },
            eliminaTask(item) {
                var i = this.listaTask.indexOf(item);
                this.listaTask.splice(i, 1);
            },
            changeTipoPratica() {
                let tpe = this.tipiPraticaEstesi.find(x=>x.tipoPratica.id==this.pratica.tipoPratica.id);
                this.listaTask = tpe.listaTask;
            },
            editNode(nodeId) {
                //alert(nodeId);
            },
            logout() {
                this.$store.dispatch('logout');
                this.$store.dispatch('setUtente', null);
                this.$router.replace({ name: "login" });
            },
            checkData(d) {
                return gCheckData(d);
            },
            localData(v) {
                return gLocalData(v);
            },
            localDateTime(v) {
                return gLocalDateTime(v);
            },
            serverData(stringDate) {
                return gServerData(stringDate);
            },
            server2Data(stringDate) {
                return gServer2Data(stringDate);
            },
            validate() {
                this.$refs.form.validate()
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },
            descrizioneApp(v) {
                if (v == false) return "Risposta via Mail/Telefono";
                return "Risposta via App StudioPro";
            },
            descrizioneRisposta(v) {
                if (v == false) return "Non autorizzata dal cliente";
                return "Autorizzata dal cliente";
            },
            async annulla() {
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);    
                this.loadingAnnulla = false;
            },
            async salva() {

                if (!this.$refs.form.validate()) return;

                let pratica = JSON.parse(JSON.stringify(this.pratica));

                
                if (this.nuovo) 
                {
                    pratica.dataInizio = this.server2Data("1900-1-1T0");
                    pratica.dataScadenza = this.server2Data("2300-12-31T0");
                }
                else
                {
                    pratica.dataInizio = this.server2Data(pratica.dataInizio);
                    pratica.dataScadenza = this.server2Data(pratica.dataScadenza);
                }

                if(!pratica.oraInizio || this.listaOre.indexOf(pratica.oraInizio)==-1) pratica.oraInizio="00:00";
                if(!pratica.oraFine || this.listaOre.indexOf(pratica.oraFine)==-1) pratica.oraFine="00:00";

                pratica.dataScadenza = pratica.dataScadenza + "T" + pratica.oraFine;
                pratica.dataInizio = pratica.dataInizio + "T" + pratica.oraInizio;


                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    pratica: pratica,
                    listaTask: this.listaTask,
                };

                this.loadingSalva = true;

                let pra = await callPost('api/pratica/saveservcon', paramJson);

                if (pra)
                {
                    this.pratica.id = pra.id;
                    this.nuovo = false;
                    bus.$emit('pratica-changed', { lista: [this.pratica.id], sessionID: "-1" });
                    //bus.$emit('pratica-snack-succes',{});
                    await this.internalCreated(Snack.success);
                    this.loadingSalva = false;
                }
                else
                {
                    await this.internalCreated(Snack.error);
                }

                this.loadingSalva = false;
            },
            async internalCreated(snack) {

                this.listaOperatore = this.$store.state.listaOperatore;

                let param = [];
                this.tipiPraticaEstesi = await callService("api/pratica/listaTipiPratica", param);

                param = [];
                this.listaOre = await callService('api/calendarioService/listaOre', param);
                
                param = [this.pratica.id];
                let praticaEstesa = await callService("api/pratica/pratica", param);   

                let pratica = praticaEstesa.pratica;

                this.pratica = pratica;

                this.addebito = praticaEstesa.addebito;
                praticaEstesa.listaTask.forEach(
                    function(t) {
                        if(t.operatore==null) t.operatore = {ID:"0"};
                });
                this.listaTask = praticaEstesa.listaTask;

                //devo proteggere la lista vuota?

                if (this.nuovo) {
                    this.pratica.operatoreCreazione.id = this.$store.state.utente.operatore.id;
                }
                if(this.copia) {
                    this.pratica.id="0";
                    this.pratica.descrizione = "Duplicato " + this.pratica.descrizione;
                }

                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            },
            async showServizio(pratica,copia=false) {
                this.type = null;
                this.copia = copia;
                this.dialog = true;
                this.$nextTick(() => {
                    this.$refs.form.resetValidation();
                });
                this.loading = true;
                this.nuovo = pratica.id == "0";
                this.pratica = pratica;
                await this.internalCreated(Snack.nothing);

                this.loading = false;
                this.type = "flowchart LR";
                this.$nextTick(() => {
                     this.$refs.form.resetValidation();
                });
            }
        },
        async created() {
            let theme = this.$cookie.get('theme') == "true"; //trasformo da string a bool
            this.theme = theme;

            var vm = this;

            bus.$on('dialog-show-servcon', this.showServizio);
        },
        beforeDestroy() {
            bus.$off('dialog-show-servcon', this.showServizio);
        }
    }

</script>

