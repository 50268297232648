<template>
    <v-dialog v-model="dialog" persistent max-width="550px">
        <v-card>
            <v-card-title class="text-subtitle-1 grey lighten-2">
                Inattività Client
            </v-card-title>
            <v-card-text>
                <br />
                <p>Logout previsto fra <b>{{secondi}}</b> secondi...</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="deep-orange darken-4" dark
                       @click="onOk">
                   Continua
                </v-btn>               
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { bus } from '@/main.js'

    export default {
        data: () => ({
            dialog:false,
            time:0,
            timerId: null
        }),
        methods: {                      
            onOk() {
                clearInterval(this.timerId);
                this.dialog = false;
            },
            onOpen() {
                this.dialog = true;
                this.time=30000;
                this.setTimer();
            },
            setTimer() {
                this.timerId = setInterval(() => {
                  this.time -= 1000;
                  if (this.time ==0) {
                    clearInterval(this.timerId);
                    bus.$emit("logout");
                    this.dialog = false;
                  }
                }, 1000);
            }
        },
        computed: {
            secondi() {
              return this.time / 1000;
            }
        },
        created() {           
            bus.$on('msg-idle', this.onOpen)            
        },
        beforeDestroy() {
            bus.$off('msg-idle', this.onOpen);
        }
    }

</script>

