<template>
    <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card>
            <v-card-title class="text-subtitle-1 grey lighten-2">
                {{title}}
            </v-card-title>
            <v-card-text>
                <br />
                <p>{{message}}</p>                
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="deep-orange darken-4" dark
                       @click="onOk">
                    Ok
                </v-btn>               
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import { bus } from '@/main.js'

    export default {
        data: () => ({
            dialog: false,
            message: '',
            title:''
        }),
        methods: {                      
            onOpen({ message, title }) {
                this.message = message;
                this.title = title;
                this.dialog = true;
            },
            onOk() {
                this.dialog = false;
            }
        },
        async created() {           
            bus.$on('msg-simple-open', this.onOpen)            
        },
        beforeDestroy() {
            bus.$off('msg-simple-open', this.onOpen);
        }
    }

</script>

