<template>
    <v-card class="ma-2" :loading="loading" color="blue-grey" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-message-badge-outline
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Messaggio non letti: {{listaMessaggi.length}}
            </span>
        </v-card-title>
        <v-card-text>
            <v-data-table :headers="headers"
                          :items="listaMessaggi"
                          :items-per-page="5"
                          class="elevation-0 transparent"
                          disable-items-per-page
                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': '',
                                            'items-per-page-options':[5]
                                          }"
                          no-data-text="Nessun messaggio da leggere">
                <template v-slot:item.nominativo="{ item }">
                    <v-row>
                        <v-col cols="2">
                            <v-badge dot color="red" />

                            <v-list-item-avatar color="indigo">
                                <span v-if="!item.isAvatar" class="white--text text-h7">{{item.inizialiNormalizzate}}</span>
                                <img style="object-fit:cover" v-if="item.isAvatar" :src="item.avatar" />
                            </v-list-item-avatar>
                        </v-col>
                        <v-col col="8" class="text-truncate">
                            <v-list-item-title>{{item.nominativo}} <span class="caption">[{{item.messaggio.dataInvioMessaggio}}]</span></v-list-item-title>
                            <v-list-item-subtitle>{{item.messaggio.testo}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.messaggio.allegato">[Allegato]</v-list-item-subtitle>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.data="{ item }">
                    <span class="text-subtitle" v-text="item.messaggio.dataInvioMessaggio" />
                </template>
                <template v-slot:item.pratica="{ item }">
                    <span v-text="item.pratica.tipoPratica.descrizione" />
                    <br />
                    <span class="font-weight-light" v-text="item.pratica.descrizione" />
                </template>
                <template v-slot:item.apri="{ item }">
                    <v-btn icon @click="apriMessaggi(item)" class="ml-2">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>

    </v-card>
</template>



<script>
    import { callService, gLocalDateTime } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {        
        data: () => ({
            loading: false,
            hCard: 450,
            listaMessaggi: [],
            headers: [
                { text: 'Utente', value: 'nominativo' },
                { text: '', value: 'apri'},
            ],
            timer: null
        }),
        methods: {
            cancelAutoUpdate() {
                clearInterval(this.timer);
            },
            localDateTime(v) {
                return gLocalDateTime(v);
            },
            apriMessaggi(item) {
                this.$router.replace({ name: "chat", query: { id: item.idMittente } });
            }, 
            async internalCreated() {
                this.loading = true;
                let param = [this.$store.state.utente.operatore.id];
                let lista = await callService('api/chat/listaMessaggiOperatoreDaLeggere', param);
                let listaMessaggi = [];
                for (const msgDest of lista) {
                    let msg = msgDest.messaggioMittente;

                    if (msg.mittenteCliente) {
                        listaMessaggi.push(
                            {
                                id: msg.id,
                                idMittente: msg.mittenteCliente.id,
                                nominativo: msg.mittenteCliente.denominazioneNormalizzata,
                                avatar: msg.mittenteCliente.avatarLink,
                                isAvatar: msg.mittenteCliente.isAvatar,
                                inizialiNormalizzate: msg.mittenteCliente.inizialiNormalizzate,
                                messaggio: msg
                            },
                        );
                    }
                    if (msg.mittenteOperatore) {
                        listaMessaggi.push(
                            {
                                id: msg.id,
                                idMittente: msg.mittenteOperatore.id,
                                nominativo: msg.mittenteOperatore.denominazioneNormalizzata,
                                avatar: msg.mittenteOperatore.avatarLink,
                                isAvatar: msg.mittenteOperatore.isAvatar,
                                inizialiNormalizzate: msg.mittenteOperatore.inizialiNormalizzate,
                                messaggio: msg
                            },
                        );
                    }                    
                }
                this.listaMessaggi = listaMessaggi;
                this.loading = false;

                //timer di refresh lo imposto una sola volta altrimenti va in loop

                if(!this.timer) this.timer = setInterval(this.internalCreated, 5*60000); //ogni 5 minuti
            }
        },        
        async created() {
            this.$updaterHub.$on('chat-changed', this.internalCreated);
            await this.internalCreated();
        },        
        beforeDestroy() {
            this.cancelAutoUpdate();
            this.$updaterHub.$off('chat-changed', this.internalCreated);
        }
    }</script>

