<template>
    <v-form ref="form"
            v-model="valid"
            class="mt-4 ml-4 mr-4"
            lazy-validation>

        <v-card class="ma-2" :loading="loading">

            <template>
                <v-tabs>
                    <v-tab>Impostazioni di base</v-tab>
                    <v-tab-item>
                        <v-row class="mt-0 mx-2">
                            <v-col cols="6">
                                <v-text-field v-model="configurazione.sumUp_uid"
                                              label="MerchantCode SumUp"
                                              required
                                              outlined
                                              dense></v-text-field>
                            </v-col>
                            <v-col cols="5">
                                <v-text-field v-model="configurazione.sumUp_key"
                                              label="SecretKey SumUp"
                                              required
                                              outlined
                                              dense></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n4 mx-2">
                            <v-col cols="10">
                                <v-text-field v-model="configurazione.stripe_pk"
                                              label="SecretKey Stripe"
                                              required
                                              outlined
                                              dense></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="mt-n4 mx-2">
                            <!--<v-col cols="3">
            <v-text-field v-model="configurazione.tolleranzaFido"
                          label="Tolleranza Fido"
                          required
                          outlined
                          dense></v-text-field>
        </v-col>-->
                            <v-col cols="5">
                                <v-select v-model="configurazione.durataSlot"
                                          :items="listaDurate"
                                          item-value="id"
                                          item-text="value"
                                          label="Durata periodi di disponibilità"
                                          :rules="[v=> !!v || 'indicare la durata']"
                                          outlined
                                          dense></v-select>
                            </v-col>
                            <v-col cols="5">
                                <v-select v-model="configurazione.giorniSlot"
                                          :items="listaGiorni"
                                          item-value="id"
                                          item-text="value"
                                          label="Giorni per prenotare una disponibilità"
                                          :rules="[v=> !!v || 'indicare i giorni di visibilità']"
                                          outlined
                                          dense></v-select>
                            </v-col>
                        </v-row>

                        <v-row v-if="!$store.state.conf.isService"
                               class="mt-n4 mx-2">
                            <v-col cols="5">
                                <v-select v-model="configurazione.tipoAttivita.id"
                                          :items="listaTipoAttivita"
                                          item-value="id"
                                          item-text="descrizione"
                                          label="Tipo attività assegnata all'operatore responsabile delle scadenze"
                                          :rules="[v=> !!v || 'indicare il tipo attività da considerare']"
                                          outlined
                                          dense></v-select>
                            </v-col>
                            <v-col cols="5">
                                <v-select v-model="configurazione.operatore.id"
                                          :items="listaOperatori"
                                          item-value="id"
                                          item-text="denominazioneNormalizzata"
                                          label="Operatore a cui assegnare le scadenze per tutti gli altri clienti"
                                          outlined
                                          dense></v-select>
                            </v-col>
                        </v-row>

                        <v-row class="mt-n6">
                            <v-col cols="12">
                                <v-data-table :headers="headersConti"
                                              :items="listaConti"
                                              item-key="conto.id"
                                              ref="tabella"
                                              :loading="loading"
                                              :items-per-page="5"
                                              class="elevation-1 ma-2"
                                              :footer-props="{
                                                    'items-per-page-text':'Pagina da',
                                                    'items-per-page-all-text': '',
                                                    'items-per-page-options':[5]
                                                    }"
                                              loading-text="Caricamento dati..."
                                              no-data-text="Nessun conto presente in archivio"
                                              no-results-text="Nessuna conto corrispodende ai criteri di ricerca">
                                    <template slot="header.operazione">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" color="info" class="ml-2"
                                                       v-on="on"
                                                       icon @click="newConto">
                                                    <v-icon>mdi-folder-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>
                                                Aggiungi Conto
                                            </span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:item.descrizione="{ item }">
                                        <v-text-field v-model="item.descrizione"
                                                      hide-details
                                                      outlined
                                                      dense
                                                      style="font-size:smaller"
                                                      :rules="[v=> !!v || '']">
                                        </v-text-field>
                                    </template>
                                    <template v-slot:item.bic="{ item }">
                                        <v-text-field v-model="item.bic"
                                                      hide-details
                                                      outlined
                                                      dense
                                                      style="font-size:smaller">
                                        </v-text-field>
                                    </template>
                                    <template v-slot:item.intestazione="{ item }">
                                        <v-text-field v-model="item.intestazione"
                                                      hide-details
                                                      outlined
                                                      dense
                                                      style="font-size:smaller"
                                                      :rules="[v=> !!v || '']">
                                        </v-text-field>
                                    </template>
                                    <template v-slot:item.iban="{ item }">
                                        <v-text-field v-model="item.iban"
                                                      hide-details
                                                      outlined
                                                      dense
                                                      style="font-size:smaller"
                                                      :rules="[v=> !!v || '']">
                                        </v-text-field>
                                    </template>
                                    <template v-slot:item.isDefault="{ item }">
                                        <v-checkbox v-model="item.isDefault">
                                        </v-checkbox>
                                    </template>
                                    <template v-slot:item.operazione="{ item }">
                                        <v-tooltip bottom v-if="isPro || ($store.state.utente && $store.state.utente.isGestoreStudio) || ($store.state.utente && $store.state.utente.isReception)">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs"
                                                       v-on="on"
                                                       icon @click="eliminaConto(item)"
                                                       color="red darken-3" class="ml-2">
                                                    <v-icon color="error darken-2">mdi-trash-can-outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>
                                                Elimina Conto
                                            </span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>

                    </v-tab-item>

                    <v-tab>Modelli dei documenti</v-tab>
                    <v-tab-item>
                        <v-data-table no-data-text="Non sono presenti documenti"
                                      :headers="headers"
                                      :items="tabellamodelli"
                                      :items-per-page="5"
                                      class="elevation-1"
                                      :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,10,20,-1]
                                          }">
                            <template v-slot:item.dataUltimaModifica="{ item }">
                                <span v-text="localData(item.dataUltimaModifica)" />
                            </template>
                            <template v-slot:item.azioni="{ item }">
                                <v-icon small
                                        @click="deleteDocumento(item)">
                                    mdi-delete
                                </v-icon>
                            </template>

                        </v-data-table>

                        <template>
                            <v-row class="mt-4">
                                <v-col cols="10">
                                    <v-file-input v-model="fileModello"
                                                  label="File Modello"
                                                  outlined
                                                  type="file"
                                                  dense>
                                    </v-file-input>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn @click="upload">
                                        Upload
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </v-tab-item>

                    <v-spacer></v-spacer>

                    <v-btn class="mt-2 mr-2" color="teal" :dark="valid"
                           @click="submit"
                           :loading="loadingSalva"
                           :disabled="!valid">
                        Salva
                    </v-btn>
                    <v-btn class="mt-2 mr-2"
                           color="orange darken-2" dark
                           :loading="loadingAnnulla"
                           @click="annulla">
                        Annulla modifiche
                    </v-btn>
                </v-tabs>
            </template>

            <v-snackbar v-model="snackSuccess"
                        timeout="2000"
                        color="success">
                <v-icon dark>
                    mdi-checkbox-marked-circle
                </v-icon>
                Operazione eseguita con successo.
            </v-snackbar>
            <v-snackbar v-model="snackError"
                        timeout="2000"
                        color="error">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Errore durante l'esecuzione dell'operazione.
            </v-snackbar>
            <v-snackbar v-model="snackCancel"
                        timeout="2000"
                        color="warning">
                <v-icon dark>
                    mdi-alert-circle
                </v-icon>
                Modifiche annullate.
            </v-snackbar>
        </v-card>
    </v-form>
</template>



<script>

    import { callService, callPost, callPostMultipart, euro, gLocalData, gCheckData, gServerData, Snack } from '@/modules/utilities.js'

    export default {
        data: () => ({
            valid: true,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            configurazione: {
                tolleranzaFido: '',
                payPal_uid: '',
                payPal_key: '',
                sumUp_uid: '',
                sumUp_key: '',
                durataSlot: 30,
                giorniSlot: 1,
                tipoAttivita : { id:''},
                operatore : {id:''}
            },
            tabellamodelli: [], 
            listaDurate: [], 
            listaGiorni: [], 
            listaOperatori: [], 
            listaTipoAttivita: [], 
            loading: true,
            loadingSalva: false,
            loadingAnnulla: false,
            idOperatore: '',
            hCard: 200,
            isSelecting: false,
            selectedFile: null,
            fileModello: null,
            headers: [
                { text: 'Modello', value: 'nome', sortable: true },
                {
                    text: 'Data ultima modifica',
                    align: 'start',
                    sortable: true,
                    value: 'dataUltimaModifica',
                },
                { text: 'Status', value: 'status', sortable: true },
                { text: 'Dimensione', value: 'dimensione' },
                { text: '', value: 'azioni', sortable: false }
            ],
            pag: 1,
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            },                                 
            chartOptions: {
                height: 120,
                legend: { position: 'none' },
                backgroundColor: { fill:'transparent' },
                vAxis: { format: '€ #' }
            },
            headersConti: [
                { text: 'Descrizione conto', value: 'descrizione', width: '20%' },
                { text: 'Intestazione conto', value: 'intestazione', width: '20%' },
                { text: 'IBAN', value: 'iban', width: '25%' },
                { text: 'Causale', value: 'bic', width: '25%' },
                { text: 'Default', value: 'isDefault' },
                { text: '', value: 'operazione', sortable: false }, 
            ],
            listaConti: [],            
        }),
        methods: {
            async upload() {
                const formData  = new FormData();
                formData.append('fileModello',this.fileModello);                
                formData.append('partition', this.$store.state.partition);    
                formData.append('username', this.$store.state.utente.username);    
                formData.append('token', this.$store.state.utente.token);    
                const esito = await callPostMultipart('api/documenti/uploadModello', formData);
                if (esito) {
                    this.loading = true;
                    let param = [];
                    this.tabellamodelli = await callService("api/documenti/tabellamodelli", param);
                    this.fileModello = null;
                    this.loading = false;
                }
            },
            async deleteDocumento(item) {
                let param = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    documento: item
                };
                const esito = await callPost('api/documenti/eliminadocumento', param);
                if (esito) {
                    this.loading = true;
                    param = [];
                    this.tabellamodelli = await callService("api/documenti/tabellamodelli", param);
                    this.loading = false;
                }
            },
            async annulla() {
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);
                this.loadingAnnulla = false;
            },
            async submit() {
                if (!this.$refs.form.validate()) return;

                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    configurazione: this.configurazione,
                    conti: this.listaConti
                };

                this.loadingSalva = true;
                const ok = await callPost('api/configurazione/save', paramJson);
                await this.internalCreated(ok ? Snack.success : Snack.error);
                this.loadingSalva = false;
            },
            async internalCreated(snack) {
                let param = [];
                let configurazione = await callService("api/configurazione/valoriconf", param);
                this.configurazione = configurazione;
                let param2=[];
                param2=['x'];
                this.listaConti = await callService("api/conto/conti", param2);
                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
            },
            importoEuro(v) {
                return euro(v);
            },
            localData(v) {
                return gLocalData(v);
            },
            checkData(v) {
                return gCheckData(v);
            },
            serverData(v) {
                return gServerData(v);
            },
            newConto() {

                let nConto =
                {
                    descrizione: "nuovo conto"
                }

                this.listaConti.push(
                    {
                        //conto: nConto
                        descrizione: "nuovo conto"
                    })
            },
            eliminaConto(item) {
                var i = this.listaConti.indexOf(item);
                this.listaConti.splice(i, 1);
            },  
        },
        computed: {
            importoPagamentoEuro() {
                return euro(this.importoPagamento);
            },
            descrizioneOperatore() {
                let p = this.listaoperatori.find(x => x.id === this.idOperatore);
                if (p) return p.denominazioneNormalizzata;
                else return "";
            }
        },
        async created() {

            let param = [];
            this.tabellamodelli = await callService("api/documenti/tabellamodelli", param);

            param = [];
            this.configurazione = await callService("api/configurazione/valoriconf", param);

            this.listaOperatori = JSON.parse(JSON.stringify(this.$store.state.listaOperatore));
            this.listaTipoAttivita = JSON.parse(JSON.stringify(this.$store.state.listaTipoAttivita));

            param = [];
            this.listaDurate = await callService("api/configurazione/listadurate", param);    

            param = [];
            this.listaGiorni = await callService("api/configurazione/listagiorni", param);

            let param2=[];
            param2=['x'];
            this.listaConti = await callService("api/conto/conti", param2);
            this.loading = false;

        }
    }</script>
