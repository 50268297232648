<template>
    <v-card class="ma-2" :loading="loading" color="teal" dark>
        <v-card-title>
            <v-icon x-large left>
                mdi-account-group
            </v-icon>
            <span class="text-subtitle-1 font-weight-medium">
                Accessi Clienti in App - Clienti attivi/invitati: {{nClientiApp}}/{{nClientiInvitati}}
            </span>
        </v-card-title>
        <v-card-text>

            <v-data-table no-data-text="Non sono presenti utenti"
                          :headers="headers"
                          :items="utentiClienti"
                          :items-per-page="5"
                          class="teal"
                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,10,20,-1]
                                          }">
                <template v-slot:item.utente.dataUltimoLogin="{ item }">
                    <span v-text="localDateTime(item.utente.dataUltimoLogin)" />
                </template>
            </v-data-table>
        </v-card-text>
        <template slot="progress">
            <v-progress-linear color="white" indeterminate></v-progress-linear>
        </template>
    </v-card>
</template>

<script>
    import { gLocalDateTime } from '@/modules/utilities.js'

    export default {
        data: () => ({
            loading: false,
            hCard: 450,
            utentiClienti: [],
            nClientiApp: 0,
            nClientiInvitati: 0,
            headers: [
                { text: 'Cliente', align: 'start', sortable: false, value: 'utente.username', },
                { text: 'Nominativo', align: 'start', sortable: false, value: 'cliente.denominazioneNormalizzata', },
                { text: 'Data di Login', align: 'start', sortable: false, value: 'utente.dataUltimoLogin', },
                ]
        }),   
        methods: {
            localDateTime(v) {
                return gLocalDateTime(v);
            },
        },
        async created() {
            this.loading = true;
            let utentiClienti = this.$store.state.utentiClienti;
            this.nClientiInvitati = utentiClienti.length;
            this.utentiClienti = utentiClienti.filter(uc => uc.utente.dataUltimoLogin != null);
            this.nClientiApp = this.utentiClienti.length;
            this.loading = false;
        }
    }</script>

