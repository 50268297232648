<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-card-title>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="search"
                                                      append-icon="mdi-magnify"
                                                      label="Ricerca una partizione cliente"
                                                      single-line
                                                      :hint="hintSelezione"
                                                      persistent-hint
                                                      outlined
                                                      clearable
                                                      @keyup="searchChange"
                                                      @click:clear="searchClear"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-data-table :headers="headers"
                                          :items="partizioni"
                                          ref="tabella"
                                          :loading="loading"
                                          :items-per-page="nPag"
                                          class="elevation-1"
                                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                                          items-per-page-all-text="ciao"
                                          @current-items="filterItems"
                                          @pagination="pagination"
                                          :search="search"
                                          loading-text="Caricamento dati..."
                                          no-data-text="Nessuna partizione cliente presente in archivio"
                                          no-results-text="Nessuna partizione cliente corrispodende ai criteri di ricerca">
                                <template v-slot:item.menu="{item}">
                                    <!--<v-menu offset-y max-width="200">
                                        <template v-slot:activator="{ on, attrs }">
                                            <td v-bind="attrs"
                                                v-on="on">
                                                <v-icon color="orange darken-2">
                                                    mdi-launch
                                                </v-icon>
                                            </td>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="anagrafica(item)">
                                                <v-list-item-title>
                                                    <v-icon color="deep-orange darken-4">
                                                        mdi-account
                                                    </v-icon>
                                                    Anagrafica
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>-->
                                    <v-row>
                                        <v-col class="mx-n4">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-3" icon @click="anagrafica(item)"
                                                            v-bind="attrs"
                                                            v-on="on">
                                                        <v-icon color="brown">
                                                            mdi-database-cog
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Anagrafica</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </template>

                                <template v-slot:item.isTest="{ item }">
                                    <v-simple-checkbox v-model="item.isTest"
                                                       disabled>
                                    </v-simple-checkbox>
                                </template>

                                <!--<template v-slot:item.isPersona="{ item }">
                                    <v-simple-checkbox v-model="item.isPersona"
                                                       disabled>
                                    </v-simple-checkbox>
                                </template>-->

                                <template v-slot:item.dataScadenza="{ item }">
                                    <div v-if="item.dataScadenza < new Date()">
                                        <span v-text="localData(item.dataScadenza)" />
                                    </div>
                                    <div v-else>
                                        <span v-text="localData(item.dataScadenza)" />
                                    </div>
                                </template>

                                <template v-slot:item.listaNomi="{ item }">
                                    <v-chip v-if="item.isContabilita"
                                            class="ma-2"
                                            color="blue"
                                            text-color="white">
                                        Contabilita`
                                    </v-chip>
                                    <v-chip v-if="item.isLavoro"
                                            class="ma-2"
                                            color="red"
                                            text-color="white">
                                        Lavoro
                                    </v-chip>
                                    <v-chip v-if="item.isPulizia"
                                            class="ma-2"
                                            color="green"
                                            text-color="white">
                                        Servizi
                                    </v-chip>

                                </template>


                                <!--<template v-slot:item.isReception="{ item }">
                                    <v-simple-checkbox v-model="item.isReception"
                                                       disabled>
                                    </v-simple-checkbox>
                                </template>-->

                                <!--<template v-slot:item.isGestoreStudio="{ item }">
                                    <v-simple-checkbox v-model="item.isGestoreStudio"
                                                       disabled>
                                    </v-simple-checkbox>
                                </template>-->

                                <template v-slot:item.mail="{ item }">
                                    <span v-text="item.mail" />
                                    <br />
                                    <span class="font-weight-light" v-text="item.telefono" />
                                </template>


                                <!--<template v-slot:item.operatore.isCancellato="{ item }">
                                    <v-tooltip bottom v-if="item.operatore.isCancellato">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="blue"
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   @click="abilita(item,true)">
                                                <v-icon color="error darken-2">mdi-account-off</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Abilita agente</span>
                                    </v-tooltip>

                                    <v-tooltip bottom v-else-if="!item.operatore.isCancellato">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon
                                                   color="blue"
                                                   v-bind="attrs"
                                                   v-on="on"
                                                   @click="abilita(item,false)">
                                                <v-icon color="green darken-2">mdi-account</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Disabilita agente</span>
                                    </v-tooltip>
                                </template>-->

                            </v-data-table>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <partizione-dialog />

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-container>   

</template>




<script>
    import { callService, callPost, euro, Snack, gLocalData } from '@/modules/utilities.js'
    import PartizioneDialog from '@/views/components/LPLAppDialog.vue'
    import { bus } from '@/main.js'

    export default {
                components: {
                    PartizioneDialog
                },  
        data: () => ({
                    snackSuccess: false,
                    snackError: false,
                    snackCancel: false,
                    snackRemote: false,
                    dialogUtente: false,
                    selezione: [],
                    filtro: [],
                    statoAll: false,
                    loading: true,
                    showError: false,
                    viewMenuUtente: false,
                    x: 0,
                    y: 0,
                    search: '',
                    partizione: {
                        id:''
                    },
                    partizioni: [],
                    headers: [
                        { text: 'Azioni', value: 'menu', width: '5%' },
                        { text: 'Denominazione', value: 'denominazione', width: '15%' },
                        { text: 'Partizione', value: 'partition', width: '15%' },
                        { text: 'Mail/Telefono', value: 'mail', width: '15%' },
                        { text: 'Agente', value: 'agente.denominazioneNormalizzata', width: '15%' },
                        { text: 'Scadenza', align: 'center', value: 'dataScadenza' },
                        { text: 'Moduli', value: 'listaNomi', width: '25%' },
                        { text: 'Test', value: 'isTest', width: '25%' },
                    ],
                    nPag:8,
                    oggetto:'',
                    testo:'',
                    notifica: true,
                    moneyOptions: {
                        locale: "it-IT",
                        prefix: "€",
                        suffix: "",
                        length: 10,
                        precision: 2
                    }
                }),
                methods: {
                    searchChange(){
                        this.selezione=[];
                    },
                    localData(v) {
                        return gLocalData(v);
                    },
                    searchClear() {
                        this.selezione=[];
                    },
                    filterItems(v){
                        this.$nextTick(() => {
                            this.filtro=this.$refs.tabella.$children[0].filteredItems;
                        });
                    },
                    anagrafica(partizione) {
                        this.partizione = JSON.parse(JSON.stringify(partizione));
                        bus.$emit('dialog-show-lplapp', this.partizione);
                    },                    
                    onSnackSuccess() {
                            this.snackSuccess = true;
                    },
                    async abilita(item, value) {                        
                        bus.$emit('msg-open', { message: value ? "Abilitare l'agente?" :"Disabilitare l'agente?", item });
                        bus.$on('msg-cancel', this.abilitaCancel);
                        if (value) bus.$on('msg-ok', this.abilitaOk);
                        else       bus.$on('msg-ok', this.disabilitaOk);
                    },
                    async abilitaCancel(item) {
                        bus.$off('msg-cancel', this.abilitaCancel);
                        bus.$off('msg-ok', this.abilitaOk);
                        bus.$off('msg-ok', this.disabilitaOk);
                    },
                    async abilitaOk(item) {
                        await this.setStatoUtente(item, true);
                    },
                    async disabilitaOk(item) {
                        await this.setStatoUtente(item, false);
                    },
                    async setStatoUtente(item,value) {
                        bus.$off('msg-cancel', this.abilitaCancel);
                        bus.$off('msg-ok', this.abilitaOk);

                        this.partizione = JSON.parse(JSON.stringify(item));

                        let paramJson = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            agente: this.partizione,
                            abilita: value
                        };


                        this.loading = true;

                        let result = await callPost('api/agente/abilita', paramJson);

                        if (result) {
                            bus.$emit('lplapp-changed-bus', { lista: [this.partizione.id], sessionID: "-1" });
                            this.snackSuccess = true;
                        }
                        else {
                            this.snackError = true;
                        }
                        this.loading = false;
                    },
                    pagination(pag) {
                        let nPag = pag.itemsPerPage;
                        this.$cookie.set('partizioni-pagination', nPag.toString(), { expires: '1Y' });
                    },
                    async internalCreated(snack) {

                        this.loading = true;
                        let param = [];
                        this.partizioni = await callService('api/lplapp/lista', param);
                        this.loading = false;
                        this.search = "";
                        this.snackSuccess = (snack == Snack.success);
                        this.snackError = (snack == Snack.error);
                        this.snackCancel = (snack == Snack.cancel);
                        this.snackRemote = (snack == Snack.remote);
                    },  

                },
                computed: {
                    hintSelezione() {
                        if(this.selezione.length==1) return "1 agente selezionato su "+this.filtro.length;
                        if(this.selezione.length>1) return this.selezione.length+" agenti selezionati su "+this.filtro.length;
                        return "";
                    },
                },
                async created() {

                    this.loading = false;                    
                    bus.$on('partizione-snack-succes', this.onSnackSuccess);

                    this.nPag = Number(this.$cookie.get('partizioni-pagination')); //leggo il coockie della paginazione

                    await this.internalCreated(Snack.nothing);

                },

                beforeDestroy() {                    
                    bus.$off('partizione-snack-succes', this.onSnackSuccess);
                }
            }</script>
