import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr'
import { callService} from '../modules/utilities'
import Store from '../store/store'

export default {

    async install(Vue) {
        let param = [];
        let conf = await callService("api/confSite", param, true);
        
        if(!conf.isLplSync) return;
        
        const connection = new HubConnectionBuilder().withUrl(conf.syncServer, {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
        }).configureLogging(LogLevel.Information)
        .build();
        
/*
        const connection = new HubConnectionBuilder().withUrl("http://localhost:5051/syncHub", {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
        }).configureLogging(LogLevel.Information)
        .build();

      */  

        let startedPromise = null
        function start() {
            startedPromise = connection.start()
                .then(function () {
                    console.info("SignalR OK");
                })
                .catch(err => {
                    console.error('"SignalR KO"', err)
                    return new Promise((resolve, reject) =>
                        setTimeout(() => start().then(resolve).catch(reject), 5000))
                });
            return startedPromise;
        }

        connection.onclose(() => start());

        start();

        const syncHub = new Vue();

        Vue.prototype.$syncHub = syncHub;

        
        connection.on('UpdateEvent',async  (partition) => {     
            
            if (Store.state.partition != partition) return;

            console.info("UpdateEvent / partition:"+partition);

            let param = [partition];

            let ok=await callService("api/sync/updateEvent", param,true);

        }); 
    }
}
