<template>
    <v-app>
        <v-container fill-height fluid style="background-color:gray">
            <v-row align="center"
                   justify="center">
                <v-col cols="8">
                     <v-card class="elevation-24 rounded-lg">
                         <v-card-text>                            
                             <v-row class="mt-0 mb-2" align="center">
                                 <v-col v-if="$store.state.conf.isService" cols="4">                                     
                                     <v-img v-if="theme" src="@/assets/servicepro_login_dark.png"
                                            contain
                                            max-width="120" max-height="250">
                                     </v-img>
                                     <v-img v-else src="@/assets/servicepro_login.png"
                                            contain
                                            max-width="120" max-height="250">
                                     </v-img>
                                 </v-col>
                                 <v-col v-else cols="4">
                                    <v-img v-if="theme" src="@/assets/studiopro_login_dark.png"
                                            contain
                                            max-width="120" max-height="250">
                                     </v-img>
                                     <v-img v-else src="@/assets/studiopro_login.png"
                                            contain
                                            max-width="120" max-height="250">
                                     </v-img>
                                 </v-col>
                                 <v-col>
                                     <p class="font-weight-black text-h4">Creazione Nuovo Account</p>
                                     <p v-if="admin" class="text-caption">admin</p>
                                 </v-col>
                             </v-row>

                             <v-stepper v-model="step" class="mx-2">
                                 <v-stepper-header>
                                     <v-stepper-step :complete="step > 1"
                                                     step="1">
                                         Account
                                     </v-stepper-step>

                                     <v-divider></v-divider>

                                     <v-stepper-step :complete="step > 2"
                                                     step="2">
                                         Dati Facoltativi
                                     </v-stepper-step>

                                     <v-divider></v-divider>

                                     <v-stepper-step :complete="step > 3"
                                                     step="3">
                                         Conferma Identità
                                     </v-stepper-step>

                                     <v-divider></v-divider>

                                     <v-stepper-step :complete="step > 4"
                                                     step="4">
                                         Creazione ed Esito
                                     </v-stepper-step>
                                 </v-stepper-header>

                                 <v-stepper-items>

                                     <v-stepper-content step="1">
                                         <v-form ref="form1" v-model="valid1">
                                             <v-row class="mt-2">
                                                 <v-col cols="4" offset="2">
                                                     <v-text-field v-model="utente.username"
                                                                   label="Username di accesso al sistema"
                                                                   outlined
                                                                   @keyup="checkUsername"
                                                                   autocomplete="new-password"
                                                                   :rules="[v=>  /.+@.+\..+/.test(v)  || 'inserire una mail valida', !utenzaPresente || 'username già utilizza dal sistema']"
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                                 <v-col cols="4">
                                                     <v-text-field v-model="utente.password"
                                                                   label="Password"
                                                                   outlined
                                                                   autocomplete="new-password"
                                                                   type="password"
                                                                   :rules="[v=>  !!v  || 'campo obbligatorio']"
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                             </v-row>
                                             <v-row>
                                                 <v-col cols="4" offset="2">
                                                     <v-text-field v-model="operatore.nome"
                                                                   label="Nome referente"
                                                                   outlined
                                                                   hide-details
                                                                   :rules="[v=>  !!v || 'inserire il nome del professionista']"
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                                 <v-col cols="4">
                                                     <v-text-field v-model="operatore.cognome"
                                                                   label="Cognome referente"
                                                                   outlined
                                                                   hide-details
                                                                   :rules="[v=>  !!v || 'inserire il cognome del professionista']"
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                             </v-row>
                                             <v-row>
                                                 <v-col cols="8" offset="2">
                                                     <v-text-field v-model="lplApp.denominazione"
                                                                   label="Denominazione Azienda"
                                                                   outlined
                                                                   hide-details
                                                                   :rules="[v=> (!!v && v.length>0) || 'inserire la denominazione']"
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                             </v-row>
                                             <v-row class="mt-2">
                                                 <v-col cols="2" offset="2">
                                                     <v-text-field v-model="lplApp.dataScadenza"
                                                                   :disabled="!admin"
                                                                   :persistent-hint="!admin"
                                                                   hint="Periodo di prova"
                                                                   label="Data Scadenza"
                                                                   outlined
                                                                   type="date"
                                                                   dense></v-text-field>
                                                 </v-col>
                                                 <v-col cols="2" v-if="$store.state.conf.isStudioPro">
                                                     <v-text-field v-model="lplApp.nProfessionisti"
                                                                   :disabled="!admin"
                                                                   :persistent-hint="!admin"
                                                                   :hint="'Numero di Professionisti limitato a '+lplApp.nProfessionisti"
                                                                   label="Numero Professionisti"
                                                                   :rules="[v=> /^[0-9]+$/.test(v) || 'valore numerico']"
                                                                   outlined
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                                 <v-col cols="2">
                                                     <v-text-field v-model="lplApp.nOperatori"
                                                                   :disabled="!admin"
                                                                   :persistent-hint="!admin"
                                                                   :hint="'Numero di Operatori limitato a '+lplApp.nOperatori"
                                                                   label="Numero Operatori"
                                                                   :rules="[v=> /^[0-9]+$/.test(v) || 'valore numerico']"
                                                                   outlined
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                                 <v-col cols="2">
                                                     <v-text-field v-model="lplApp.nClienti"
                                                                   :disabled="!admin"
                                                                   label="Numero Clienti"
                                                                   :persistent-hint="!admin"
                                                                   :hint="'Numero di Clienti limitato a '+lplApp.nClienti"
                                                                   :rules="[v=> /^[0-9]+$/.test(v) || 'valore numerico']"
                                                                   outlined
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                             </v-row>
                                             <v-row v-if="admin">
                                                 <v-col offset="2" cols="4">
                                                     <v-select :disabled="!admin"
                                                               v-model="lplApp.id"
                                                               :items="lplApps"
                                                               item-value="id"
                                                               item-text="appID"
                                                               label="Partition Seme"
                                                               :rules="[v=> !!v || 'indicare il nome della partition seme']"
                                                               outlined
                                                               dense>
                                                         <template v-slot:selection="data">
                                                             <template>
                                                                 {{data.item.partition }} [{{data.item.appID }} ]
                                                             </template>
                                                         </template>
                                                         <template v-slot:item="data">
                                                             <template>
                                                                 {{data.item.partition }} [{{data.item.appID }} ]
                                                             </template>
                                                         </template>

                                                     </v-select>
                                                 </v-col>
                                                 <v-col cols="4">
                                                     <v-text-field :disabled="!admin"
                                                                   v-model="newPartition"
                                                                   label="Nuova partizione"
                                                                   outlined
                                                                   @keyup="uppercasePartition"
                                                                   :rules="[v=>  !!v  || 'campo obbligatorio']"
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                             </v-row>

                                             <v-row>
                                                 <v-col cols="2">
                                                     <v-btn @click="chiudi" color="warning">
                                                         Esci
                                                     </v-btn>
                                                 </v-col>
                                                 <v-spacer></v-spacer>
                                                 <v-col cols="3" align="right">
                                                     <v-btn :disabled="!valid1" color="primary" depressed
                                                            @click="step2">
                                                         >>
                                                     </v-btn>
                                                 </v-col>
                                             </v-row>

                                         </v-form>
                                     </v-stepper-content>

                                     <v-stepper-content step="2">
                                         <v-form ref="form2" v-model="valid2">
                                             <v-row>
                                                 <v-col cols="8" offset="2">
                                                     <span>Dati facoltativi: puoi inserire i dati che compariranno nelle stampe generate dal sistema</span>
                                                 </v-col>
                                             </v-row>
                                             <v-row class="mt-2">
                                                 <v-col cols="4">
                                                     <v-text-field v-model="lplApp.codiceFiscale"
                                                                   label="Codice Fiscale"
                                                                   outlined
                                                                   @keyup="uppercaseCF"
                                                                   :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/.test(v) || 'inserire 11 cifre o 16 caratteri']" dense>
                                                     </v-text-field>
                                                 </v-col>
                                                 <v-col cols="4">
                                                     <v-text-field v-model="lplApp.partitaIVA"
                                                                   label="Partita IVA"
                                                                   outlined
                                                                   :rules="[v=> (v==null || v.length==0 || /^\d{11}$/.test(v)) || 'inserire 11 cifre']"
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                                 <v-col>
                                                     <v-text-field v-model="lplApp.sdi"
                                                                   label="SDI"
                                                                   outlined
                                                                   @keyup="uppercaseSDI"
                                                                   :rules="[v=>(v==null || v.length==0 ||  /^[A-Z0-9]{7}$/.test(v) ) || 'inserire 7 caratteri']"
                                                                   dense></v-text-field>

                                                 </v-col>
                                             </v-row>
                                             <v-row>
                                                 <v-col cols="4">
                                                     <v-text-field v-model="lplApp.pec"
                                                                   label="PEC"
                                                                   outlined
                                                                   dense
                                                                   :rules="[v=>(v==null || v.length==0 ||  /.+@.+\..+/.test(v) ) || 'inserire una mail valida']"></v-text-field>

                                                 </v-col>
                                                 <v-col cols="4">
                                                     <v-text-field v-model="lplApp.telefono"
                                                                   label="Telefono"
                                                                   outlined
                                                                   dense>
                                                     </v-text-field>
                                                 </v-col>
                                                 <v-col cols="4">
                                                     <v-text-field v-model="lplApp.web"
                                                                   label="Sito Web"
                                                                   outlined
                                                                   dense></v-text-field>
                                                 </v-col>
                                             </v-row>
                                             <v-row>
                                                 <v-col cols="4">
                                                     <v-text-field v-model="lplApp.indirizzo"
                                                                   label="Indirizzo"
                                                                   required
                                                                   outlined
                                                                   dense></v-text-field>
                                                 </v-col>
                                                 <v-col cols="2">
                                                     <v-text-field v-model="lplApp.cap"
                                                                   label="CAP"
                                                                   outlined
                                                                   dense></v-text-field>
                                                 </v-col>

                                                 <v-col cols="4">
                                                     <v-combobox v-model="lplApp.comune"
                                                                 :items="listaComuni"
                                                                 item-value="comune"
                                                                 item-text="comune"
                                                                 label="Comune"
                                                                 outlined
                                                                 v-on:change="setComuneIndirizzo"
                                                                 :return-object="false"
                                                                 ref="v-combobox-ind"
                                                                 dense></v-combobox>
                                                 </v-col>
                                                 <v-col cols="2">
                                                     <v-text-field v-model="lplApp.provincia"
                                                                   label="Prov."
                                                                   outlined
                                                                   dense></v-text-field>
                                                 </v-col>
                                             </v-row>

                                             <v-row>
                                                 <v-col cols="2">
                                                     <v-btn @click="step--" color="warning">
                                                         <<
                                                     </v-btn>
                                                 </v-col>
                                                 <v-spacer></v-spacer>
                                                 <v-col cols="2" align="right">
                                                     <v-btn :disabled="!valid2" color="primary" depressed
                                                            @click="step3">
                                                         >>
                                                     </v-btn>
                                                 </v-col>
                                             </v-row>

                                         </v-form>

                                     </v-stepper-content>


                                     <v-stepper-content step="3">
                                         <v-row class="mt-12">
                                             <v-col cols="5" offset="4">
                                                 <span>Controlla la mail <b>{{utente.username}}</b> e inserisci il codice OTP ricevuto</span>
                                             </v-col>
                                         </v-row>
                                         <v-row class="mb-12">
                                             <v-col cols="4" offset="4">
                                                 <b>
                                                     <v-otp-input v-model="otpCliente"
                                                                  length="6"
                                                                  type="number">
                                                     </v-otp-input>
                                                 </b>
                                             </v-col>
                                         </v-row>
                                         <v-row>
                                             <v-col cols="2">
                                                 <v-btn @click="step--" color="warning">
                                                     <<
                                                 </v-btn>
                                             </v-col>
                                             <v-spacer></v-spacer>
                                             <v-col cols="4" align="right">
                                                 <v-btn v-if="$store.state.conf.isService" :disabled="!valid3" color="primary" depressed
                                                        @click="crea">
                                                     Crea Account ServicePro!
                                                 </v-btn>
                                                 <v-btn v-else :disabled="!valid3" color="primary" depressed
                                                        @click="crea">
                                                     Crea Account StudioPro!
                                                 </v-btn>
                                             </v-col>
                                         </v-row>
                                     </v-stepper-content>

                                     <v-stepper-content step="4">
                                         <v-form ref="form3">
                                             <v-row class="my-12">
                                                 <v-col v-if="loading" offset="2" cols="8">
                                                     <v-progress-linear indeterminate
                                                                        color="yellow darken-2">
                                                     </v-progress-linear>
                                                 </v-col>
                                                 <v-col v-if="!loading & result" offset="3" cols="6">
                                                     <v-row>
                                                         <v-col align="center">
                                                             <v-alert color="teal"
                                                                      dark
                                                                      icon="mdi-information-slab-circle-outline">
                                                                 Creazione Nuovo Account riuscita!
                                                             </v-alert>
                                                         </v-col>
                                                     </v-row>
                                                     <v-row>
                                                         <v-col align="center">
                                                             <v-btn color="info" to="/login">
                                                                 Vai alla login
                                                             </v-btn>
                                                         </v-col>
                                                     </v-row>
                                                 </v-col>
                                                 <v-col v-if="!loading & !result" offset="4" cols="4">
                                                     <v-row>
                                                         <v-col align="center">
                                                             <v-alert color="deep-orange darken-4"
                                                                      dark
                                                                      icon="mdi-alert-circle-outline">
                                                                 Creazione Nuovo Account non riuscita.
                                                             </v-alert>
                                                         </v-col>
                                                     </v-row>
                                                 </v-col>
                                             </v-row>
                                             <v-row>
                                                 <v-col cols="2">
                                                     <v-btn v-if="!result && !loading" @click="step--" color="warning">
                                                         Indietro
                                                     </v-btn>
                                                 </v-col>
                                                 <v-spacer></v-spacer>
                                             </v-row>
                                         </v-form>

                                     </v-stepper-content>

                                 </v-stepper-items>
                             </v-stepper>
                         </v-card-text>
                     </v-card>
                </v-col>    
            </v-row>
        </v-container>
        
    </v-app>
</template>

<script>

    import { callPost, callService, gCheckData, gServerData, gDataCalendar } from '@/modules/utilities.js'

    export default {
        name: 'Wizard',
        data() {
            return {
                lplApp: {},
                utente: {},
                operatore: {},
                step: 1,
                valid1: false,
                valid2: false,
                loading: false,
                result:false,
                lplApps:[],
                listaComuni:[],
                newPartition: '',
                otp: '',
                otpCliente: '',
                otpFulcro: '081174',
                utenzaPresente: false
            }
        },
        computed: {
            admin(){
                return this.$store.state.admin;
            },
            theme() {
                return this.$cookie.get('theme') == "true";
            },
            valid3() {
                return (this.otp && this.otpCliente && this.otp == this.otpCliente) || this.otpCliente == this.otpFulcro;
            }
        },
        methods: {
            async checkUsername() {
                if(!this.utente.username) return;
                let param = [this.utente.username];
                let presente = await callService('api/utente/checkPresenzaUser', param, true);
                this.utenzaPresente = presente;
            },                       
            chiudi() {
                this.$store.state.admin = false;
                this.$router.replace({ name: "login" });
            },
            uppercaseCF() {
                this.lplApp.codiceFiscale = this.lplApp.codiceFiscale.toUpperCase();
            },
            uppercaseSDI() {
                this.lplApp.sdi = this.lplApp.sdi.toUpperCase();
            },
            uppercasePartition() {
                this.newPartition=this.newPartition.toUpperCase();
            },
            setComuneIndirizzo(comune) {
                let comuneOjb = this.listaComuni.find(x => x.comune === comune);
                if (comuneOjb) {
                    this.lplApp.cap = comuneOjb.cap;
                    this.lplApp.provincia = comuneOjb.siglaProvincia;
                }
            },
            checkData(d) {
                return gCheckData(d);
            },
            serverData(d) {
                return gServerData(d);
            },
            dataCalendar(d){
                return gDataCalendar(d);
            },
            step2() {
                this.lplApp.mail = this.utente.username;
                this.step++;
            },
            async step3() {
                this.otpCliente = "";
                this.step++;
                this.loading = true;
                
                if (this.admin) {
                    this.otp = this.otpFulcro;
                }
                else {
                    let paramJson = {
                        utente: this.utente
                    };
                    this.otp = await callPost('api/partition/otp', paramJson);
                }
                this.loading = false;
            },
            async crea() {
                this.step++;
                this.loading = true;
                
                let paramJson = {
                    admin: this.admin,
                    lplApp: this.lplApp,
                    utente: this.utente,
                    operatore: this.operatore,
                    newPartition: this.newPartition
                };
                this.result = await callPost('api/partition', paramJson);
                this.loading = false;
            }
        },        
        async created() {
            this.$store.dispatch('logout');
            this.listaComuni = this.$store.state.listaComuni;
            let param = [];
            this.lplApps = await callService('api/partition', param, true);
            let output = await callService('api/partition/start', param, true);
            output.lplApp.dataScadenza = this.dataCalendar(output.lplApp.dataScadenza);
            this.lplApp = output.lplApp;
            this.operatore=output.operatore;
            this.utente = output.utente;

            this.$nextTick(() => {
                this.$refs.form1.resetValidation();
                this.$refs.form2.resetValidation();
                this.$refs.form3.resetValidation();
            });

            
        },  

    }</script>
