<template>
    <v-dialog v-model="dialog" persistent max-width="1500px">

        <v-card :loading="loading">
            <v-card-text>
                <v-form ref="form"
                        v-model="valid"
                        class="mt-4 ml-4 mr-4"
                        lazy-validation>
                    <v-tabs>
                        <v-tab>Gestione Tariffa</v-tab>
                        <v-tab-item>
                            <v-row class="mt-4">
                                <v-col cols="4">
                                    <v-text-field v-model="tipoServizio.descrizione"
                                                  label="Descrizione"
                                                  required
                                                  outlined
                                                  dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-select v-model="tipoServizio.tipoConsulenza.id"
                                              :items="listaConsulenze"
                                              item-value="id"
                                              item-text="descrizione"
                                              label="Tipo Consulenza"
                                              :rules="[v=> !!v || 'indicare la tipologia di consulenza']"
                                              :disabled="!this.nuovo"
                                              outlined
                                              dense></v-select>
                                </v-col>

                                <v-col cols="4">
                                    <v-select v-model="tipoServizio.tipoPeriodicita.id"
                                              :items="listaPeriodicita"
                                              item-value="id"
                                              item-text="descrizione"
                                              label="Tipo Periodicità"
                                              :rules="[v=> !!v || 'indicare la tipologia di periodicità']"
                                              :disabled="!this.nuovo"
                                              outlined
                                              dense></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2">
                                    <v-checkbox class="mt-0"
                                                v-model="tipoServizio.isSistema"
                                                color="green"
                                                disabled
                                                :label="descrizioneSistema(tipoServizio.isSistema)">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                    <v-checkbox class="mt-0"
                                                v-model="tipoServizio.isOscurato"
                                                color="red"
                                                :label="descrizioneOscurato(tipoServizio.isOscurato)">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox class="mt-0"
                                                v-model="tipoServizio.isSpot"
                                                color="blue"
                                                disabled
                                                :label="descrizioneSpot(tipoServizio)">
                                    </v-checkbox>
                                </v-col>
                            </v-row>

                        </v-tab-item>


                        <v-spacer></v-spacer>
                        <v-btn class="mt-2 mr-2" color="teal" :dark="valid"
                               @click="salva"
                               :loading="loadingSalva"
                               :disabled="!valid">
                            Salva
                        </v-btn>
                        <v-btn class="mt-2 mr-2" color="orange darken-2" dark
                               :loading="loadingAnnulla"
                               @click="annulla">
                            Annulla modifiche
                        </v-btn>
                        <v-btn class="mt-2 mr-2"
                               color="blue darken-2" dark
                               @click.stop="dialog = false">
                            Chiudi
                        </v-btn>
                    </v-tabs>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
    </v-dialog>
</template>

<script>

    import { callService, callPost, Snack, euro, gLocalData, gCheckData, gServerData } from '@/modules/utilities.js'
    import { bus } from '@/main.js'


    export default {
        data: () => ({
            dialog: false,
            valid: true,
            nuovo: false,
            snackSuccess: false,
            snackError: false,
            snackCancel: false,
            snackRemote: false,
            dialogDelete: false,
            loading: false,
            loadingSalva: false,
            loadingAnnulla: false,
            listaConsulenze: [],
            idtipoServizio: '',
            listaPeriodicita: [],
            moneyOptions: {
                locale: "it-IT",
                prefix: "€",
                suffix: "",
                length: 10,
                precision: 2
            }, 
            tipoServizio: {
                id:'',
                tipoConsulenza: {
                    id:''
                },
                tipoPeriodicita: {
                    id: ''
                }
            }
        }),
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" });
            }            
        },
        computed: {
            descrizioneScadenza() {
                //let p = this.listaScadenze.find(x => x.id === this.idScadenza);
                //if (p) return p.descrizione;
                //else return "";
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout');
                this.$store.dispatch('setUtente', null);
                this.$router.replace({ name: "login" });
            },
            checkData(d) {
                return gCheckData(d);
            },
            localData(v) {
                return gLocalData(v);
            },
            serverData(stringDate) {
                return gServerData(stringDate);
            },
            validate() {
                this.$refs.form.validate()
            },
            reset() {
                this.$refs.form.reset()
            },
            resetValidation() {
                this.$refs.form.resetValidation()
            },
            descrizioneSpot(v) {
                if (this.tipoServizio.isSpot && this.tipoServizio.tipoPeriodicita.id == '-1')
                {
                    return "Tariffa addebitabile solo come Addebito Spesa selezionando un singolo cliente";
                }
                else if (this.tipoServizio.isSpot)
                {
                    return "Tariffa addebitabile senza restrizioni";
                }

                else return "Tariffa addebitabile solo nelle procedure massive";
            },
            descrizioneOscurato(v) {
                if (v == false) return "Tariffa disabilitata";
                return "Tariffa utilizzabile";
            },
            descrizioneSistema(v) {
                if (v == false) return "Tariffa personalizzata";
                return "Tariffa di sistema";
            },
            async annulla() {
                this.loadingAnnulla = true;
                await this.internalCreated(Snack.cancel);    
                this.loadingAnnulla = false;
            },            
            async salva() {

                if (!this.$refs.form.validate()) return;

                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    tipoServizio: this.tipoServizio
                };

                this.loadingSalva = true;
                let ts = await callPost('api/tariffaCliente/saveTariffa', paramJson);


                if (ts) {
                    this.tipoServizio.id = ts.tipoServizio.id;
                    this.nuovo = false;
                    bus.$emit('tariffa-changed', { lista: [this.tipoServizio.id], sessionID: "-1" });
                    bus.$emit('tariffa-snack-succes',{});
                    this.loadingSalva = false;  
                    this.dialog = false;
                }
                else {
                    await this.internalCreated(Snack.error);
                    this.loadingSalva = false;   
                }

            },
            async internalCreated(snack) {

                let param = [];
                this.listaConsulenze = await callService('api/tariffacliente/listaconsulenze', param);
                this.listaPeriodicita = await callService('api/tariffacliente/listaperiodicita', param);

                //alert(this.tipoServizio.id);
                param = [this.tipoServizio.id];

                let ts = await callService("api/tariffaCliente/tariffa", param);
                
                this.tipoServizio = ts;


                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            }    
        },
        async created() {           

            var vm = this;

            bus.$on('dialog-show-tariffa', async function (tipoServizio) {
                
                vm.dialog = true;
                vm.loading = true;
                vm.nuovo = tipoServizio.id == "0";
                vm.tipoServizio.id = tipoServizio.id;
                await vm.internalCreated(Snack.nothing);
                vm.loading = false;
            });   
        },
        beforeDestroy() {
            bus.$off('dialog-show-tariffa');
        }
    }

</script>

