<template>
    <v-container fluid>
        <v-slide-y-transition mode="out-in">
            <v-row>
                <v-col>
                    <template>
                        <v-card>
                            <v-card-text>
                                <v-row class="mt-n5">
                                    <v-col cols="3">
                                        <v-text-field v-model="searchText"
                                                      append-icon="mdi-magnify"
                                                      :label="$store.state.conf.isService ? 'Ricerca un servizio':'Ricerca una pratica'"
                                                      single-line
                                                      :hint="hintSelezione"
                                                      persistent-hint
                                                      outlined
                                                      clearable
                                                      @keyup="changeSearch"
                                                      dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-select v-model="operatore.id"
                                                  :items="listaOperatore"
                                                  item-value="id"
                                                  item-text="denominazioneNormalizzata"
                                                  label="Filtra per Operatore con Task assegnato"
                                                  clearable
                                                  outlined
                                                  @change="changeSearch"
                                                  dense></v-select>
                                    </v-col>
                                    <!--<v-col cols="4" class="mt-3">
                                        <v-row>
                                            <v-radio-group @change="changeSearch" hide-details class="mt-0 mx-5" v-model="statoServCon" row mandatory>
                                                <v-radio label="Tutti"
                                                         value="1"></v-radio>
                                                <v-radio label="Associati a contratti"
                                                         value="2"></v-radio>
                                                <v-radio label="Non associati a contratti"
                                                         value="3"></v-radio>
                                            </v-radio-group>
                                        </v-row>
                                    </v-col>-->
                                    <v-col cols="2" class="mt-3">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon
                                                       color="teal"
                                                       v-bind="attrs"
                                                       v-on="on"
                                                       @click="nuovo">
                                                    <v-icon class="ma-0">mdi-calendar-plus</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Nuovo Modello di Servizio</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-data-table  v-model="selezione"
                                          :headers="headers"
                                          :items="praticheEstese"
                                          ref="tabella"
                                          :item-key="pratica.id"
                                          :loading="loading"
                                          :items-per-page="nPag"
                                          progress="78"
                                          class="elevation-1 mt-n6"
                                          :footer-props="{
                                            'items-per-page-text':'Pagina da',
                                            'items-per-page-all-text': 'Tutti',
                                            'items-per-page-options':[5,8,10,20,-1]
                                          }"
                                          @current-items="filterItems"
                                          @pagination="pagination"
                                          :search="search"
                                          :custom-filter="customFilter"
                                          loading-text="Caricamento dati..."
                                          :no-data-text="$store.state.conf.isService ? 'Nessun servizio presente in archivio':'Nessuna pratica presente in archivio'"
                                          :no-results-text="$store.state.conf.isService ? 'Nessun servizio corrisponde ai criteri di ricerca':'Nessuna pratica corrisponde ai criteri di ricerca'">

                                <template v-slot:item.menu="{item}">
                                    <v-row>
                                        <v-col>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-n2" icon @click="anagrafica(item.pratica)"
                                                           v-bind="attrs"
                                                           v-on="on">
                                                        <v-icon color="orange darken-2">
                                                            mdi-launch
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Elabora Modello</span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-n6" icon @click="duplica(item.pratica)"
                                                           v-bind="attrs"
                                                           v-on="on">
                                                        <v-icon color="blue darken-2">
                                                            mdi-content-copy
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Duplica Modello</span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn class="mx-n10" icon @click="elimina(item.pratica)"
                                                           v-bind="attrs"
                                                           v-on="on">
                                                        <v-icon color="red darken-2">
                                                            mdi-trash-can-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Elimina Modello</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>

                                </template>

                                <template v-slot:item.creazione="{ item }">
                                    <span class="font-weight-light" v-text="item.pratica.operatoreCreazione.denominazioneNormalizzata" />
                                    <br />
                                    <span class="text-subtitle" v-text="localData(item.pratica.dataCreazione)" />
                                </template>
                                <template v-slot:item.pratica.tipoPratica.descrizione="{ item }">
                                    <span v-text="item.pratica.tipoPratica.descrizione" />
                                    <br />
                                    <span class="font-weight-light" v-text="item.pratica.descrizione" />
                                </template>
                                <template v-slot:item.operatori="{ item }">
                                    <v-chip small dark color="red darken-3"
                                            v-for="operatore in item.listaOperatori"
                                            :key="operatore.id"
                                            v-text="operatore.nomeInizialeCognome">
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-slide-y-transition>

        <v-snackbar v-model="snackSuccess"
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError"
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel"
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote"
                    timeout="2000"
                    color="info">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>


    </v-container>   

</template>




<script>
    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, gDataCalendar, gControllData, Snack } from '@/modules/utilities.js'
    import { bus } from '@/main.js'

    export default {
                
                data: () => ({
                    selezione: [],
                    filtro: [],
                    statoAll: false,
                    loading: true,
                    showError: false,
                    viewMenuUtente: false,
                    snackSuccess: false,
                    snackError: false,
                    snackCancel: false,
                    snackRemote: false,
                    x: 0,
                    y: 0,
                    oggetto: '',
                    testo: '',
                    search: '',
                    searchText: '',
                    praticheEstese: [],
                    pratica: {
                        id:'0',
                        tipoPratica: {
                            id: '0'
                        }
                    },
                    headers: [
                        { text: 'Azioni', value: 'menu', width: '15%' },
                        { text: 'Tipologia', value: 'pratica.tipoPratica.descrizione', width: '25%' },
                        { text: 'Creazione', value: 'creazione', width: '15%' },
                        { text: 'Operatori assegnati', value: 'operatori', sortable: false, width: '35%' },
                        { text: 'Contratti associati', value: 'contrattiAssociati', sortable: false },                      
                    ],
                    conteggioUtenti: [],
                    notifica: true,
                    operatore: { id: '' },
                    notifica1: true,
                    notifica2: true,
                    moneyOptions: {
                        locale: "it-IT",
                        prefix: "€",
                        suffix: "",
                        length: 10,
                        precision: 2
                    },
                    statoServCon: '1'
                }),
                methods: {
                    localData(v) {
                        return gLocalData(v);
                    },
                    importoEuro(v) {
                        return euro(v);
                    },
                    controllData(v) {
                        return gControllData(v);
                    },
                    dataCalendar(v) {
                        return gDataCalendar(v);
                    },
                    descrizioneApp(v) {
                        if (v == false) return "Mail/Telefono";
                        return "App StudioPro";
                    },
                    descrizioneRisposta(v) {
                        if (v == false) return "Non autorizzata";
                        return "Autorizzata";
                    },
                    async elimina(item) {
                        bus.$emit('msg-open', { message: "Eliminare la pratica? Il modello sarà eliminato da tutti i contratti a cui è associato.", item });
                        bus.$on('msg-cancel', this.eliminaCancel);
                        bus.$on('msg-ok', this.eliminaOk);
                    }, 
                    async eliminaCancel(item) {
                        bus.$off('msg-cancel', this.eliminaCancel);
                        bus.$off('msg-ok', this.eliminaOk);
                    },
                    async eliminaOk(item) {
                        bus.$off('msg-cancel', this.eliminaCancel);
                        bus.$off('msg-ok', this.eliminaOk);
                        let param = {
                            partition: this.$store.state.partition,
                            username: this.$store.state.utente.username,
                            token: this.$store.state.utente.token,
                            //sessionID: this.$store.state.sessionID,
                            idPratica: item.id
                        };

                        this.loading = true;

                        const result = await callPost('api/pratica/eliminaservcon', param);

                        if (result) {
                            await this.internalCreated(Snack.success);
                        }
                        else {
                            await this.internalCreated(Snack.error);
                        }
                        this.loading = false;
                    },
                    filterItems(v){
                        this.$nextTick(() => {
                            this.filtro=this.$refs.tabella.$children[0].filteredItems;
                        });
                    },
                    anagrafica(pratica) {
                        this.pratica = JSON.parse(JSON.stringify(pratica));
                        bus.$emit('dialog-show-servcon', this.pratica);
                    },
                    nuovo() {
                        this.pratica = {
                            id: "0",
                            tipoPratica: { id:"0"},
                            operatoreCreazione: { id:"0"}
                        }
                        bus.$emit('dialog-show-servcon', this.pratica);
                    },
                    duplica(pratica) {
                        this.pratica = JSON.parse(JSON.stringify(pratica));
                        bus.$emit('dialog-show-servcon', this.pratica, true);
                    },
                    toggleAll() {
                        this.statoAll = !this.statoAll;
                        this.$store.dispatch('setStatoAll',this.statoAll);

                        if(this.statoAll) {
                            this.selezione=this.filtro;
                        }
                        else {
                            this.selezione=[];
                        };
                        this.$store.dispatch('setSelezione',this.selezione);
                    },
                    toggleSelect() {
                        this.$nextTick(() => {
                            this.$store.dispatch('setSelezione',this.selezione);
                        });
                    },
                    async onPraticaChanged({ lista, sessionID }) {

                        if (sessionID == this.$store.state.sessionID) return;
                        for (var i = 0; i < lista.length; i++) {

                            let param = [lista[i]];
                            let praticaEstesa = await callService("api/pratica/pratica", param);

                            let pratica = praticaEstesa.pratica;

                            //cerco nella lista la pratica ricalcolata

                            const index = this.praticheEstese.findIndex(u => {
                                return u.pratica.id === pratica.id;
                            });

                            if (index >= 0) {
                                this.praticheEstese.splice(index, 1, praticaEstesa);
                            }
                            else {
                                this.praticheEstese.push(praticaEstesa);
                            }
                        }
                    },                    
                    async onPraticaDeleted({ lista, sessionID }) {

                        if (sessionID == this.$store.state.sessionID) return;
                        for (var i = 0; i < lista.length; i++) {

                            let idPratica = lista[i];
                            
                            //cerco nella lista la pratica eliminata

                            const index = this.praticheEstese.findIndex(u => {
                                return u.pratica.id === idPratica;
                            });

                            if (index >= 0) {
                                this.praticheEstese.splice(index, 1);
                            }                            
                        }
                    },
                    onSnackSuccess() {
                        this.snackSuccess = true;
                    },
                    pagination(pag) {
                        let nPag = pag.itemsPerPage;
                        this.$cookie.set('pratiche-pagination', nPag.toString(), { expires: '1Y' });
                    },
                    async internalCreated(snack) {
                        let param = [];
                        this.praticheEstese = await callService('api/pratica/listaservcon', param);

                        this.search = "x"; //faccio partire il filtro perché chiedo tutte le prstiche ma filtro sulle aperte
                        this.snackSuccess = (snack == Snack.success);
                        this.snackError = (snack == Snack.error);
                        this.snackCancel = (snack == Snack.cancel);
                        this.snackRemote = (snack == Snack.remote);
                    },       
                    customFilter(value, search, item) {
                        //console.log(JSON.stringify(item));
                        let ok = true;
                        if (ok && this.searchText) { //codice/dnz/fiscale in OR
                            let src = this.searchText.toLowerCase();
                            let ok1 = item.pratica.descrizione && item.pratica.descrizione.toLowerCase().indexOf(src) >= 0;
                            let ok2 = !ok1 && item.pratica.tipoPratica.descrizione.toLowerCase().indexOf(src) >= 0;
                            ok = ok1 || ok2;
                        }
                        
                        if (ok && this.operatore.id) {
                            let ok1 = item.listaOperatori.findIndex(o => o.id == this.operatore.id) >= 0;
                            ok = ok1;
                        }    

                        //if (ok) {
                        //    if (this.statoServCon == '1') //TUTTI//
                        //        ok = ok;
                        //    else if (this.statoServCon == '3') //NON USATE
                        //        ok = !item.pratica.isChiusa
                        //    else if (this.statoServCon == '2') //USATE
                        //        ok = item.pratica.isChiusa
                        //}
                     
                        return ok;
                    },
                    changeSearch() {
                            this.search =
                            this.searchText +
                            this.operatore.id +                        
                            this.statoServCon 
                    },
                    
                },
                computed: {
                    hintSelezione() {
                        if(this.selezione.length==1) return "1 scadenza selezionata su "+this.filtro.length;
                        if(this.selezione.length>1) return this.selezione.length+" scadenze selezionate su "+this.filtro.length;
                        return "";
                    },
                    labelNuove() {
                        return this.selezione.filter(s => s.dataInvioRichiesta == null && s.dataRisposta == null).length;
                    },
                    labelSollecito() {
                        return this.selezione.filter(s => s.dataInvioRichiesta != null && s.dataRisposta == null).length;
                    },
                    labelNoMessaggi() {
                        return this.selezione.filter(s => s.dataInvioRichiesta != null && s.dataRisposta != null).length;
                    }
                },
                async created() {

                    this.clientiEstesi = this.$store.state.clientiEstesi;
                    this.listaOperatore = this.$store.state.listaOperatore;
                    this.listaOperatoriProfessionisti = this.$store.state.listaOperatoriProfessionisti;


                    bus.$on('pratica-changed', this.onPraticaChanged);
                    bus.$on('pratica-deleted', this.onPraticaDeleted);
                    bus.$on('pratica-snack-succes', this.onSnackSuccess);

                    this.nPag = Number(this.$cookie.get('pratiche-pagination')); //leggo il coockie della paginazione

                    await this.internalCreated(Snack.nothing);

                    this.loading = false;
                },
                beforeDestroy() {
                    bus.$off('pratica-changed', this.onPraticaChanged);
                    bus.$off('pratica-deleted', this.onPraticaDeleted);
                    bus.$off('pratica-snack-succes', this.onSnackSuccess);
                }
    }
</script>
