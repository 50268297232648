<template>
    <v-app>
        <v-navigation-drawer v-if="logged" persistent
                             :mini-variant="miniVariant"
                             :clipped="clipped"
                             v-model="drawer"
                             enable-resize-watcher
                             fixed
                             app>
            <v-list v-if="$store.state.conf.isService">
                <v-list-item value="true" v-for="(item, i) in items.filter(i=>( (i.diritti[0]=='1' || (i.diritti[1]=='1' && $store.state.utente.isReception) || (i.diritti[2]=='1' && $store.state.utente.operatore.isProfessionista) || (i.diritti[3]=='1' && $store.state.utente.isGestoreStudio)) && (i.diritti[5]=='X' || (i.diritti[5]=='3' && isPulizia) || (i.diritti[5]=='4' && isManutenzione)  || (i.diritti[5]=='5' && isChiamata)) && (i.diritti[4] == '1' || i.diritti[4] == '2') && (i.diritti[7]=='1' || (i.diritti[7]=='0' && !this.$store.state.utente.operatore.isAccessoLimitato)) ))  " :key="i" :to="item.link">
                    <v-list-item-action>
                        <v-icon :color="item.color" v-html="item.icon"></v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list v-else>
                <v-list-item value="true" v-for="(item, i) in items.filter(i=>(((isStudioProLight && i.diritti[6]=='1') || (isStudioProLight && i.diritti[6]=='2') || (!isStudioProLight && i.diritti[6]!='2')) &&  (i.diritti[0]=='1' || (i.diritti[1]=='1' && $store.state.utente.isReception) || (i.diritti[2]=='1' && $store.state.utente.operatore.isProfessionista) || (i.diritti[3]=='1' && $store.state.utente.isGestoreStudio)) && (i.diritti[5]=='X' || (i.diritti[5]=='1' && isContabilita) || (i.diritti[5]=='2' && isLavoro)) && (i.diritti[4] == '1' || i.diritti[4] == '0') && (i.diritti[7]=='1' || (i.diritti[7]=='0' && !this.$store.state.utente.operatore.isAccessoLimitato)) ))  " :key="i" :to="item.link">
                    <v-list-item-action>
                        <v-icon :color="item.color" v-html="item.icon"></v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-divider v-if="admin"></v-divider>
            <v-list v-if="admin">
                <v-list-item to="wizard">
                    <v-list-item-action>
                        <v-icon color="red">mdi-newspaper-plus</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Wizard</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="copypartition">
                    <v-list-item-action>
                        <v-icon color="blue">mdi-code-braces-box</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Copy Partition</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="lplapp">
                    <v-list-item-action>
                        <v-icon color="brown">mdi-database-cog</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Partizioni</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="agenti">
                    <v-list-item-action>
                        <v-icon color="green">mdi-human-queue</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Agenti</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <!--
    <v-list-item to="rubrica">
        <v-list-item-action>
            <v-icon color="green">mdi-alphabetical-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>Rubrica</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
    -->
            </v-list>

        </v-navigation-drawer>

        <v-navigation-drawer v-if="logged" 
        v-model="viewNotifiche"
        :width="400"
        enable-resize-watcher
        right
        temporary
        app>
        <v-subheader>NOTIFICHE</v-subheader>
        <v-row dense>
            <v-btn class="ml-2" small :color="tipoNotifica ? (!this.theme ? 'light-blue accent-1':'light-blue darken-3') :''" rounded @click="switchTipoNotifica(true)">
                Tutte
            </v-btn>  
            <v-btn class="ml-2" small :color="!tipoNotifica ? (!this.theme ? 'light-blue accent-1':'light-blue darken-3') :''" rounded @click="switchTipoNotifica(false)">
                Non lette
            </v-btn>          
            <v-menu offset-y rounded>
                <template v-slot:activator="{ attrs, on }"> 
                        <v-btn class="ml-2" small rounded 
                            v-bind="attrs"
                            v-on="on">
                            ...
                        </v-btn>
                </template>          
                <v-list>
                  <v-list-item @click="eliminaNotifiche()">                   
                    <v-list-item-title>Elimina notifiche già lette</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
        </v-row>
        <v-subheader class="mt-6 text-h6" v-if="nessunaNotifica">Nessuna notifica</v-subheader>
            <v-list three-line>
                <template v-for="(item, i) in listaNotifiche.filter(n=>(!n.notifica.isLetta && !tipoNotifica) || tipoNotifica)">
                    <v-list-item @click="openNotifica(item.notifica)"
                                 :key="i">
                        <v-list-item-avatar color="indigo">
                            <span v-if="!item.utente.isAvatar" class="white--text text-h7">{{item.inizialiNormalizzate}}</span>
                            <img style="object-fit:cover" v-if="item.utente.isAvatar" :src="item.utente.avatarLink" />
                        </v-list-item-avatar>
                        <v-list-item-content> 
                            <v-list-item-title class="font-weight-bold" v-text="item.notifica.title"></v-list-item-title>
                            <v-list-item-subtitle class="text--primary" v-text="item.notifica.subTitle"></v-list-item-subtitle>
                            <v-list-item-subtitle v-text="item.notifica.message"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-list-item-action-text>{{item.notifica.dataInvio}}</v-list-item-action-text>
                            <v-badge class="ml-4 mt-6" v-if="!item.notifica.isLetta" dot color="red" overlap></v-badge>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                </template>
            </v-list>

        </v-navigation-drawer>

        <v-app-bar v-if="logged" app :clipped-left="clipped" :color="theme ? 'gray darken-3':($store.state.conf.isService ? (!$store.state.conf.isVendor ?'blue-grey darken-1':'green darken-2'):(!$store.state.conf.isVendor ? 'info':'blue darken-4'))" dark>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-btn class="d-none d-lg-flex" icon @click.stop="miniVariant = !miniVariant">
                <v-icon v-html="miniVariant ? 'mdi-chevron-right' : 'mdi-chevron-left'"></v-icon>
            </v-btn>
            <v-card class="mr-2" color="white">
                <v-img v-if="$store.state.conf.isService && $store.state.conf.isVendor" src="@/assets/prestogo_small.png"
                       max-height="35"
                       max-width="35">
                </v-img>
                <v-img v-else-if="$store.state.conf.isService && !$store.state.conf.isVendor" src="@/assets/servicepro_small.png"
                       max-height="35"
                       max-width="35">
                </v-img>
                <v-img v-else-if="!$store.state.conf.isService && $store.state.conf.isVendor" src="@/assets/pratichego_small.png"
                       max-height="35"
                       max-width="35">
                </v-img>
                <v-img v-else src="@/assets/studiopro_small.png"
                       max-height="35"
                       max-width="35">
                </v-img>
            </v-card>
            <v-toolbar-title v-if="$store.state.conf.isService && !$store.state.conf.isVendor">Service Pro</v-toolbar-title>
            <v-toolbar-title v-else-if="$store.state.conf.isService && $store.state.conf.isVendor">Presto Go</v-toolbar-title>
            <v-toolbar-title v-else-if="!$store.state.conf.isService && !$store.state.conf.isVendor">Studio Pro</v-toolbar-title>
            <v-toolbar-title v-else="!$store.state.conf.isService && $store.state.conf.isVendor">Pratiche Go</v-toolbar-title>

            <span class="ml-2 mt-1 text-caption">Anno Contabile: {{$store.state.anno}}</span>
            <span class="ml-2 mt-1 text-caption red--text" v-if="!$store.state.annoAperto">[Addebiti/Pagamenti chiusi]</span>

            <v-spacer></v-spacer>
            <v-card v-if="sourceLogo" class="mr-2" color="white">
                <v-img :src="sourceLogo" contain
                       max-height="50"
                       max-width="140">
                </v-img>
            </v-card>
            <v-toolbar-title v-text="studioName"></v-toolbar-title>   
            <v-btn v-if="false"  @click="push">
                Push
            </v-btn>               
            <v-spacer></v-spacer>
            <v-switch v-model="theme" class="mr-4"
                      @click="switchTheme"
                      persistent-hint
                      color="grey"
                      :hint="theme ? 'scuro':'chiaro'"></v-switch>
            <template>
                <div class="text-center">
                    <v-menu open-on-hover offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-avatar color="indigo lighten-2"
                                      v-bind="attrs"
                                      v-on="on">
                                <span v-if="!isAvatar" class="white--text text-h6">{{altIniziali}}</span>
                                <img style="object-fit:cover" v-if="isAvatar" :src="avatarLink" />
                            </v-avatar>
                        </template>

                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{nominativoUtente}}</v-list-item-title>
                                    <v-list-item-subtitle>{{ruoloUtente}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list>
                            <v-list-item @click="cambiaPassword">
                                <v-list-item-action>
                                    <v-icon>mdi-lock-reset</v-icon>
                                </v-list-item-action>
                                <v-list-item-title>Modifica password</v-list-item-title>
                            </v-list-item>
                        </v-list>
                        <v-list>
                            <v-list-item @click="syncDati">
                                <v-list-item-action>
                                    <v-icon>mdi-refresh</v-icon>
                                </v-list-item-action>
                                <v-list-item-title>Sincronizza Dati</v-list-item-title>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-list>
                            <v-list-item @click="logout">
                                <v-list-item-action>
                                    <v-icon>mdi-logout</v-icon>
                                </v-list-item-action>
                                <v-list-item-title>Logout</v-list-item-title>
                            </v-list-item>
                        </v-list>

                    </v-menu>
                </div>
            </template>
            <v-btn icon  @click.stop="openBell()" class="mr-2">
                <v-badge v-if="nonLette>0" color="red" :content="nonLette">
                    <v-icon large dark>mdi-bell</v-icon>
                </v-badge>
                <v-icon v-else large dark>mdi-bell</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>

        <v-footer v-if="logged" app>

            <span class="text-caption grey--text" v-if="admin">Admin - </span>
            <span class="text-caption grey--text" v-if="$store.state.conf.isVendor">&nbsp;&nbsp;&copy;&nbsp;{{new Date().getFullYear()}} - Client VRM {{$store.getters.appVersion }}</span>
            <span class="text-caption grey--text" v-else>&nbsp;Lpl Soft &nbsp;&copy;&nbsp;{{new Date().getFullYear()}} - Client VRM {{$store.getters.appVersion }}</span>
            <v-spacer></v-spacer>
            <span v-if="isAlert && !isExpired" class="deep-orange--text text--darken-4">Licenza Software in scadenza ({{dataScadenzaLicenza}})&nbsp;</span>
            <span v-if="isExpired" class="deep-orange--text text--darken-4">Licenza Software scaduta il {{dataScadenzaLicenza}}&nbsp;</span>
            <a v-if="isAlert || isExpired" :href="linkPagamento">Clicca qui per rinnovare</a>
        </v-footer>
        <v-dialog v-model="dialogCambioPassword"
                  max-width="600px"
                  persistent>
            <v-form ref="formCambioPassword" lazy-validation
                    v-model="validCambioPassword">
                <v-card style=" overflow: hidden;">
                    <v-card-title class="text-subtitle-1 grey lighten-3">Modifica Password</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row>
                            <v-col cols="8" offset="2">
                                <v-text-field v-model="nuovaPassword"
                                              label="Nuova Password"
                                              autocomplete="new-password"
                                              required
                                              outlined
                                              type='password'
                                              :rules="[v=> (!!v && v.length>0) || 'inserire la nuova password']"
                                              dense></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="8" offset="2">

                                <v-text-field v-model="confirmPassword"
                                              label="Conferma Password"
                                              autocomplete="new-password"
                                              required
                                              outlined
                                              :rules="passwordConfirmationRules"
                                              type='password'
                                              dense></v-text-field>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="teal" depressed
                               @click="salvaPassword" :dark="validCambioPassword"
                               :disabled="!validCambioPassword"
                               :loading="loadingSalva">
                            Salva
                            <v-icon right>
                                mdi-send
                            </v-icon>
                        </v-btn>
                        <v-btn @click="dialogCambioPassword=false" color="orange darken-2" dark>
                            Chiudi
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-snackbar v-model="snackSuccess" centered
                    timeout="2000"
                    color="success">
            <v-icon dark>
                mdi-checkbox-marked-circle
            </v-icon>
            Operazione eseguita con successo.
        </v-snackbar>
        <v-snackbar v-model="snackError" centered
                    timeout="2000"
                    color="error">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Errore durante l'esecuzione dell'operazione.
        </v-snackbar>
        <v-snackbar v-model="snackCancel" centered
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Modifiche annullate.
        </v-snackbar>
        <v-snackbar v-model="snackRemote" centered
                    timeout="2000"
                    color="warning">
            <v-icon dark>
                mdi-alert-circle
            </v-icon>
            Aggiornamento remoto.
        </v-snackbar>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate
                                 size="64">
            </v-progress-circular>
        </v-overlay>
        <msg-idle :dialog="dialogIdle" />
        <cliente-dialog />
        <fascicolo-dialog />
        <pratica-dialog />
        <servizio-dialog />
        <scheda-dialog />
        <servcon-dialog />
        <msg-dialog />
        <msg-simple-dialog />
        <msg-input-dialog />
        <msg-mail-dialog />

        <v-snackbar color="orange darken-1" dark @click.native="openBell()" :style="{'margin-top':calcMargin(0)}" top right v-model="notification" timeout=4000>
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="mr-4" dark>mdi-bell</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-if="listaNotificheSnack.length>1" class="font-weight-bold">Nuove notifiche</v-list-item-title>  
                    <v-list-item-title v-if="listaNotificheSnack.length==1 && pushAlert" class="font-weight-bold">{{pushAlert.title}}</v-list-item-title>  
                    <v-list-item-subtitle v-if="listaNotificheSnack.length==1 && pushAlert" >{{pushAlert.subTitle}}</v-list-item-subtitle>                     
                    <template v-slot:action="{ attrs }">
                        <v-btn
                        icon
                        color="white"                
                        v-bind="attrs"
                        @click="notification=false"
                        >
                            <v-icon dark >mdi-close</v-icon>
                        </v-btn>
                    </template>
                </v-list-item-content>
            </v-list-item>
        </v-snackbar>

    </v-app>

</template>



<script>

    import { callService, callPost, euro, gLocalData, gCheckData, gServerData, gSynch1, gSynch2, gSynch3, gSynch4, gSynch5, Snack } from '@/modules/utilities.js'
    import { bus } from '@/main.js'
    import MsgIdle from '@/views/components/MsgIdle.vue'
    import MsgDialog from '@/views/components/MsgDialog.vue'
    import MsgInputDialog from '@/views/components/MsgInputDialog.vue'
    import MsgMailDialog from '@/views/components/MsgMailDialog.vue'
    import MsgSimpleDialog from '@/views/components/MsgSimpleDialog.vue'
    import ClienteDialog from '@/views/components/ClienteDialog.vue'
    import FascicoloDialog from '@/views/components/FascicoloDialog.vue'
    import PraticaDialog from '@/views/components/PraticaDialog.vue'
    import ServizioDialog from '@/views/components/ServizioDialog.vue'
    import ServconDialog from '@/views/components/ServConDialog.vue'
    import SchedaDialog from '@/views/components/SchedaDialog.vue'
    
    export default {
        components: {
            MsgIdle,
            MsgDialog,
            MsgInputDialog,
            MsgMailDialog,
            MsgSimpleDialog,
            ClienteDialog,
            PraticaDialog,
            ServizioDialog,
            ServconDialog,
            FascicoloDialog,
            SchedaDialog
        },
        data() {
            return {
                clipped: true,
                viewNotifiche:false,
                dialogIdle: false,
                drawer: true,
                miniVariant: false,
                validCambioPassword: false,
                nuovaPassword: null,
                confirmPassword: null,
                dialogCambioPassword: false,
                loadingSalva: false,
                snackSuccess: false,
                snackCancel: false,
                snackError: false,
                snackRemote: false,
                isAccessoLimitato: false,
                overlay: false,
                right: true,
                timer: null,
                theme:'',
                listaNotifiche: [],
                listaNotificheSnack: [],
                pushAlert: null,
                notification: false,
                tipoNotifica: false,
                //il set di cifre nella stringa diritti di legge cos� (da sinistra a destra):
                //prima cifra: 1 accessibile a tutti
                //seconda cifra: 1 accessibile reception
                //terza cifra: 1 accessibile professionista
                //quarta cifra: 1 accessibile gestore studio
                //quinta cifra: 1 accessibile a StudioPro, ServicePro, 0 accessibile solo a StudioPro, 2 accessibile solo a ServicePro
                //sesta cifra: X accessibile a tutti, 1 accessibile solo a Consulenza, 2 accessibile solo a Lavoro, 3 accessibile solo in Servizi
                //settima cifra: 0 non accessibile a StudioProLight, 1 accessibile a StudiProLight, 2 accessibile solo a StudioProLight
                //ottava cifra: 0 non accessibile a chi ha accesso limitato, 1 accessibile tutti
                items: [
                    { title: 'Dashboard', color:'pink', icon: 'mdi-monitor-dashboard', link: '/dashboard', diritti: '11111X11'},
                    { title: 'Clienti', color: 'blue-grey', icon: 'mdi-account-box-multiple-outline', link: '/clienti', diritti: '11111X11' },
                    { title: 'Rubrica', color: 'brown', icon: 'mdi-alphabetical-variant', link: '/rubrica', diritti: '11111X10' },
                    { title: 'Antiriciclaggio', color: 'purple', icon: 'mdi-bank', link: '/antiriciclaggio', diritti: '11110X01' },
                    { title: 'Calendario', color: 'green', icon: 'mdi-calendar', link: '/calendario', diritti: '11110X11' },
                    { title: 'Calendario', color: 'green', icon: 'mdi-calendar', link: '/calendario_service', diritti: '11112X01' },
                    { title: 'Pagamenti C/Cliente', color: 'orange', icon: 'mdi-watch', link: '/scadenze', diritti: '11110101' },
                    { title: 'Scadenziario', color: 'pink', icon: 'mdi-calendar-sync', link: '/scadenziario', diritti: '11110101' },
                    { title: 'Pratiche', color: 'blue', icon: 'mdi-text-box-search-outline', link: '/pratiche', diritti: '11110X11' },
                    { title: 'Servizi', color: 'blue', icon: 'mdi-text-box-search-outline', link: '/pratiche', diritti: '11112X01' },
                    { title: 'Chat', color: 'teal', icon: 'mdi-message-text-outline', link: '/chat', diritti: '11111X11' },
                    { title: 'Cassa', color: 'brown', icon: 'mdi-cash-multiple', link: '/cassa', diritti: '01111X10' },
                    { title: 'Report', color: 'cyan', icon: 'mdi-finance', link: '/report', diritti: '00110X01' },
                    { title: 'Report', color: 'cyan', icon: 'mdi-finance', link: '/reportlight', diritti: '00110X21' },
                    { title: 'Utenti', color: 'amber', icon: 'mdi-account-multiple', link: '/utenti', diritti: '00011X10' },
                    { title: 'Statistiche', color: 'teal', icon: 'mdi-chart-areaspline', link: '/statistiche', diritti: '01112X00' },
                    { title: 'Servizi Contrattuali', color: 'red', icon: 'mdi-table-cog', link: '/servcon', diritti: '01112X01' },
                    { title: 'Anno Contabile', color: 'blue', icon: 'mdi-calendar-multiple-check', link: '/anno', diritti: '11111X01' },
                    { title: 'Tariffe', color: 'grey', icon: 'mdi-calculator-variant-outline', link: '/tariffe', diritti: '00010X01' },
                    { title: 'Configurazione', color: 'cyan', icon: 'mdi-cog', link: '/configurazione', diritti: '00010X11' },
                    { title: 'Configurazione sistema', color: 'cyan', icon: 'mdi-cog', link: '/configurazione', diritti: '00012X11' },
                ],
            }
        },
        name: 'App',
        watch: {
            isIdle: function (newIdle, oldIdle) {
                if(newIdle && this.$store.state.logged) {
                    bus.$emit("msg-idle");
                }
            }
        },
        computed: {
            admin(){
                return this.$store.state.admin;
            },
            sourceLogo() {
                return this.$store.state.sourceLogo;
            },
            studioName() {
                return this.$store.state.studioName;
            },
            logged() {
                return this.$store.state.logged
            },
            avatarLink() {
                return this.$store.state.utente.avatarLink;
            },
            isAvatar() {
                return this.$store.state.utente.isAvatar;
            },
            nominativoUtente() {
                return this.$store.state.utente.operatore.denominazioneNormalizzata;
            },
            nonLette(){
                let n = this.listaNotifiche.filter(n=>!n.notifica.isLetta).length;
                return n;
            },
            ruoloUtente() {
                if (this.$store.state.utente.isGestoreStudio && !this.$store.state.utente.operatore.professionista) return "Gestore Studio";
                if (this.$store.state.utente.isGestoreStudio && this.$store.state.utente.operatore.professionista) return "Professionista/Gestore Studio";
                if (this.$store.state.utente.operatore.professionista) return "Professionista";
                if (this.$store.state.utente.isReception) return "Reception";
                return "Operatore";
            },
            altIniziali() {
                return this.$store.state.utente.operatore.inizialiNormalizzate;
            },
            passwordConfirmationRules() {
                return [
                    () => (this.nuovaPassword && this.confirmPassword &&  this.nuovaPassword === this.confirmPassword) || 'le password devono coincidere',
                    v => !!v || 'confermare la password'
                ];
            },
            isIdle() {
			    return this.$store.state.idleVue.isIdle;
            },
            isAlert() {
                return this.$store.state.isAlert;
            },
            isExpired() {
                return this.$store.state.isExpired;
            },
            dataScadenzaLicenza() {
                return this.localData(this.$store.state.lplApp.dataScadenza);
            },
            linkPagamento() {
                return this.$store.state.lplApp.linkPagamento;
            },
            isLavoro() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isLavoro;
            },
            isContabilita() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isContabilita;
            },
            isPulizia() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isPulizia;
            },
            isManutenzione() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isManutenzione;
            },
            isChiamata() {
                return this.$store.state.lplApp && this.$store.state.lplApp.isChiamata;
            },
            isServicePro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isPulizia || this.$store.state.lplApp.isChiamata || this.$store.state.lplApp.isManutenzione);
            },
            isStudioPro() {
                return this.$store.state.lplApp && (this.$store.state.lplApp.isLavoro || this.$store.state.lplApp.isContabilita);
            },
            isStudioProLight() {
                return this.$store.state.parametri && this.$store.state.parametri.isStudioProLight;
            },
            nessunaNotifica() {
                return this.listaNotifiche.filter(n=>(!n.notifica.isLetta && !this.tipoNotifica) || this.tipoNotifica).length ==0;
            }
        },
        methods: {            
            async syncDati() {
                this.overlay = true;
                await gSynch1();
                await gSynch2();
                await gSynch3();
                await gSynch4();
                await gSynch5();

                //aggiorno tutti i clienti e gli utenti e i loro calendari
                bus.$emit('cliente-changed', { lista: this.$store.state.clientiEstesi.map(c => c.cliente.id), sessionID: "-1" });
                bus.$emit('utente-changed', { lista: this.$store.state.utenti.map(u => u.id), sessionID: "-1" });
                bus.$emit('calendario-changed', { lista: this.$store.state.utenti.map(u => u.id), sessionID: "-1" });

                //utente connesso
                let id = this.$store.state.utente.id;
                this.$store.state.utente = this.$store.state.utenti.find(u => u.id == id);

                //Aggiungere altri refresh qui


                //
                this.overlay = false;
            },            
            async onUtenteChanged({ lista, sessionID }) {
                if (sessionID == this.$store.state.sessionID) return;                        

                for (var i = 0; i < lista.length; i++) {

                    let param = [lista[i]];
                    let output = await callService("api/utente", param);
                    let utente = output.utente;

                    //cerco nella lista il cliente ricalcolato

                    const index = this.$store.state.utenti.findIndex(u => {
                        return u.id === utente.id;
                    });

                    if (index >= 0) {
                        this.$store.state.utenti.splice(index, 1, utente);
                    }
                    else {
                        this.$store.state.utenti.push(utente);
                    }
                }
            },
            async onClienteDeleted({ lista, sessionID}) {
                if (sessionID == this.$store.state.sessionID) return;

                for (var i = 0; i < lista.length; i++) {

                    //cerco nella lista il cliente eliminato

                    const index = this.$store.state.clientiEstesi.findIndex(ce => 
                        ce.cliente.id === lista[i]
                    );

                    if (index >= 0) {
                        //console.error("ID SPLICE RICEVUTO:"+clienteEsteso.cliente.id);
                        this.$store.state.clientiEstesi.splice(index, 1);
                    }                    
                }

            },
            async onClienteChanged({ lista, sessionID}) {

                if (sessionID == this.$store.state.sessionID) return;

                for (var i = 0; i < lista.length; i++) {

                    let param = [this.$store.state.idAnnoContabile, lista[i]];
                    let clientiEstesiDelta = await callService('api/cliente/listaestesa', param);
                    let clienteEsteso = clientiEstesiDelta[0];

                    //cerco nella lista il cliente ricalcolato

                    const index = this.$store.state.clientiEstesi.findIndex(ce => 
                         ce.cliente.id === clienteEsteso.cliente.id
                    );

                    if (index >= 0) {
                        //console.error("ID SPLICE RICEVUTO:"+clienteEsteso.cliente.id);
                        this.$store.state.clientiEstesi.splice(index, 1, clienteEsteso);
                    }
                    else {
                        //console.error("ID PUSH RICEVUTO:"+clienteEsteso.cliente.id);
                        this.$store.state.clientiEstesi.push(clienteEsteso);
                    }
                }
            },
            goDashboard() {
                this.$router.replace({ name: "dashboard" }).catch(()=>{});
            },
            logout() {
                this.$store.state.admin = false;
                this.$store.dispatch('logout');
                this.$store.dispatch('setUtente', null);
                this.$router.replace({ name: "login" }).catch(()=>{});
            },
            switchTheme() {
                this.$vuetify.theme.dark = this.theme;
                this.$cookie.set('theme', this.theme,  { expires: '1Y' });
            },
            cambiaPassword() {
                this.dialogCambioPassword = true;
                this.$nextTick(() => {
                    this.$refs.formCambioPassword.reset();
                    this.$refs.formCambioPassword.resetValidation();
                });
            },
            async salvaPassword() {
                if (!this.$refs.formCambioPassword.validate()) return;

                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    sessionID: this.$store.state.sessionID,
                    nuovaPassword: this.nuovaPassword,
                    confirmPassword: this.confirmPassword
                };
                this.loadingSalva = true;
                const ok = await callPost('api/utente/aggiornaPassword', paramJson);
                this.dialogCambioPassword = false;
                await this.internalCreated(ok ? Snack.success : Snack.error);
                this.loadingSalva = false; 
            },
            async internalCreated(snack) {
                this.snackSuccess = (snack == Snack.success);
                this.snackError = (snack == Snack.error);
                this.snackCancel = (snack == Snack.cancel);
                this.snackRemote = (snack == Snack.remote);

            },
            cancelAutoUpdate() {
                clearInterval(this.timer);
            },
            async checkNotifiche({title,subTitle,message}) {

                if(!this.$store.state.logged) return;
                let param = [this.$store.state.utente.operatore.id];
                this.listaNotifiche = await callService('api/notifiche/lista', param); 

                this.listaNotificheSnack.push(
                    { title: title,
                      subTitle: subTitle,
                      message: message
                    }
                );

                this.pushAlert = this.listaNotificheSnack[0];

                this.notification=true;

            },     
            async refreshNotifiche() {
                if(!this.$store.state.logged) return;
                let param = [this.$store.state.utente.operatore.id];
                this.listaNotifiche = await callService('api/notifiche/lista', param);
            },         
            async openNotifica(item,i) {
                item.isLetta = true;
                
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    id: item.id                   
                };
                const ok = await callPost('api/notifiche/impostaLettura', paramJson);

                if(item.type=="SERVIZIO") {       
                    let param = [item.iD3];
                    let output = await callService('api/pratica/pratica', param);             
                    bus.$emit('dialog-show-servizio', output.pratica);                   
                }    
                if(item.type=="CHAT") {                    
                    this.$router.replace({ name: "chat", query: { id: item.iD1 } });
                } 
                
                if(item.type=="TASK") {                    
                    let param = [item.iD3];
                    let output = await callService('api/pratica/pratica', param);             
                    bus.$emit('dialog-show-pratica', output.pratica);                    
                }
                if(item.type=="SCHEDA") {       
                    let param = [item.iD1];
                    let cliente = await callService("api/cliente", param)
                    bus.$emit('dialog-show-scheda', cliente);
                }    

                this.notification=false;
            },  
            localData(d) {
                return gLocalData(d);
            },
            calcMargin(i) {
                return (60+i*100) + 'px';
            } ,
            push() {
                this.$updaterHub.$emit('notifiche-changed', {title: "Pippo Maniero", subTitle: "Messaggio Chat", message: "Lorem ipsum possom"});
            },  
            openBell() {
                this.viewNotifiche = true;
                this.notification=false;
                this.listaNotificheSnack=[];
                this.pushAlert = null;
            },
            switchTipoNotifica(v) {
                this.tipoNotifica = v;
            },
            async eliminaNotifiche() {
                let paramJson = {
                    partition: this.$store.state.partition,
                    username: this.$store.state.utente.username,
                    token: this.$store.state.utente.token,
                    idOperatore: this.$store.state.utente.operatore.id
                };
                const ok = await callPost('api/notifiche/eliminaNotifiche', paramJson);
                this.refreshNotifiche();
            }
        },
        created(){

            bus.$on('cliente-changed', this.onClienteChanged);
            bus.$on('cliente-deleted', this.onClienteDeleted);
            bus.$on('utente-changed-bus', this.onUtenteChanged);
            this.$updaterHub.$on('cliente-changed', this.onClienteChanged);
            this.$updaterHub.$on('cliente-deleted', this.onClienteDeleted);
            this.$updaterHub.$on('utente-changed', this.onUtenteChanged);            
            this.$updaterHub.$on('notifiche-changed', this.checkNotifiche);
            this.$updaterHub.$on('notifiche-refresh', this.refreshNotifiche);

            bus.$on('logout', this.logout);
            let theme = this.$cookie.get('theme')=="true"; //trasformo da string a bool
            this.theme = theme;
            this.$vuetify.theme.dark = theme;
            this.refreshNotifiche();
            if (!this.timer) this.timer = setInterval(this.refreshNotifiche, 2 * 60000); //2minuti

        },     
        beforeDestroy() {
            this.cancelAutoUpdate();
            bus.$off('cliente-changed', this.onClienteChanged);
            bus.$off('utente-changed-bus', this.onUtenteChanged);

            this.$updaterHub.$off('cliente-changed', this.onClienteChanged);
            this.$updaterHub.$off('utente-changed', this.onUtenteChanged);
            this.$updaterHub.$off('notifiche-changed', this.checkNotifiche);
            this.$updaterHub.$off('notifiche-refresh', this.refreshNotifiche);

        },
        mounted() {
            if (!this.$store.state.logged) {
                this.$router.replace({ name: "login" }).catch(()=>{});
            }
        }
    }</script>

<style>
    table.v-table tbody td {
        font-size: 28px !important;
    }
</style>



